import React, { useState, useEffect } from 'react';
import Modal from 'react-responsive-modal';
import { IoIosStar } from "react-icons/io";
import CartComponent from '../home/cart/cart';
import { LuMinusCircle, LuPlusCircle } from "react-icons/lu";
import '../dish-detail-popup/Dish-popup.css'

const DetailsPopup = ({ isOpen, onClose, dishDetails, addToCart }) => {
    const [quantity, setQuantity] = useState(1);
    const [selectedSize, setSelectedSize] = useState({});
    const [crust, setCrust] = useState('');
    const [flavour, setFlavour] = useState('');
    const [sauce, setSauce] = useState('');
    const [total, setTotal] = useState(0);
    const [addontotal, setAddonTotal] = useState(0);
    let [addons, setAddons] = useState([]);

    useEffect(() => {
        getTotalPrice();
    }, [addontotal, selectedSize, addons, crust, flavour, sauce, quantity, setTotal, setSelectedSize, dishDetails]);

    const getTotalPrice = () => {
        let price = 0;
        if (dishDetails?.price) {
            price = (dishDetails?.price * quantity) + price;
        } else if (selectedSize && selectedSize.price) {
            price = (selectedSize.price * quantity);
        } else if (dishDetails?.sizes && dishDetails.sizes.length > 0) {
            const defaultSize = dishDetails.sizes[0];
            setSelectedSize(defaultSize);
            price = (defaultSize.price * quantity);
        }
        setTotal(price + addontotal);
    };

    const increaseQuantity = () => {
        setQuantity(quantity + 1);
        getTotalPrice();
    };

    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
        getTotalPrice();
    };

    const getAddons = (addon, selected) => {
        if (selected) {
            addon.selected = selected;
            setAddons((prevArray) => [...prevArray, addon]);
            setAddonTotal((prevTotal) => prevTotal + addon.addonPrice);
        } else if (!selected) {
            addon.selected = selected;
            setAddons((addons.filter((item) => {
                if (item._id === addon._id && addontotal > 0) {
                    setAddonTotal((prevTotal) => prevTotal - item.addonPrice);
                }
                return item._id !== addon._id;
            })))
        }
    }

    const renderStars = (rating) => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            if (i < rating) {
                stars.push(<IoIosStar key={i} className='star-ico' />);
            } else {
                stars.push(<IoIosStar key={i} className='star-ico' id='black-ico' />);
            }
        }
        return stars;
    };


    if (!isOpen || !dishDetails) {
        return null;
    }

    const handleAddToCart = () => {
        // if(!dishDetails?.price || dishDetails?.price === 0 || dishDetails?.price === undefined || dishDetails?.price === null){
        //     selectedSize=dishDetails?.sizes[0]
        // }

        let cartItems = {
            dish: dishDetails,
            selectedAddons: addons,
            quantity: quantity,
            size: selectedSize,
            crust: crust,
            flavour: flavour,
            sauce: sauce
        }
        if (addToCart && typeof addToCart === 'function') {
            if (dishDetails.sizes.length > 0) {
                addToCart({
                    cartItems: cartItems,
                    id: dishDetails._id,
                    name: dishDetails.dishName,
                    quantity: quantity,
                    price: selectedSize.price,
                    image: dishDetails.picture,
                });
                dishDetails.price = selectedSize.price;

            } else {

                addToCart({
                    cartItems: cartItems,
                    id: dishDetails._id,
                    name: dishDetails.dishName,
                    quantity: quantity,
                    price: dishDetails.price,
                    image: dishDetails.picture,
                });
            }
            closeModel();
        }
        console.log({
            cartItems: cartItems,
            id: dishDetails._id,
            name: dishDetails.dishName,
            quantity: quantity,
            price: dishDetails.price,
            image: dishDetails.picture,
        })
    };

    const setSize = (size) => {
        setSelectedSize(size);
    }
    const closeModel = () => {
        onClose();
        setTotal(0);
        setQuantity(1);
        setSelectedSize({});
        setCrust('');
        setFlavour('');
        setSauce('');
        setAddonTotal(0);
        setAddons([]);
    }

    return (
        <div>
            <Modal className='details-modal' open={isOpen} onClose={closeModel} center>
                <div>
                    <div className='modal-header'>
                        <img src={dishDetails?.picture} className='dish-image' alt='dish-img' />
                    </div>
                    <div className="modal-body">
                        <h2 className='dish-name'>{dishDetails.dishName}</h2>
                        {dishDetails?.price && <p className='price-home'>Rs.{dishDetails?.price} </p>}
                        {!dishDetails?.price && <p className='price-home'>Rs.{dishDetails?.sizes[0]?.price} </p>}
                        {/* {(!dishDetails?.price || dishDetails?.price === 0 || dishDetails?.price === undefined || dishDetails?.price === null) && <p className='price-home'>Rs.{dishDetails?.sizes[0]?.price}</p>} */}

                    </div>
                    {dishDetails.description !== '' && <div className="modal-details">
                        <p className="modal-description">
                            <b>Description: </b>
                            <p>{dishDetails?.description}</p>
                        </p>
                    </div>
                    }
                    <div className="restaurant-details-sec">
                        <p className='details-title'>{dishDetails?.user?.restaurantName}</p>
                        <p className="ratings">({dishDetails?.user?.rating})
                            {renderStars(dishDetails?.user?.rating)}

                        </p>
                    </div>
                    <div className="modal-sec">
                        {dishDetails.sizes.length > 0 && <div className="modal-addons">
                            <p className='addons-title'>Sizes</p>
                            {dishDetails.sizes.map((size) => (
                                <div className='addons-list'>
                                    <div className="radio-sec">
                                        <input type="radio" name='size' onChange={() => setSize(size)} className='radio-btn' />
                                        <label className='addon-name'>{size.size}</label>
                                    </div>
                                    <p className='addon-price'>Rs.{size.price}</p>
                                </div>
                            ))}
                        </div>
                        }
                        {dishDetails.addons.length > 0 && <div className="modal-addons">
                            <p className='addons-title'>Addons</p>
                            {dishDetails.addons.map((addon) => (
                                <div className='addons-list'>
                                    <div className="radio-sec">
                                        <input type="checkbox" checked={addon.selected} onChange={e => getAddons(addon, e.target.checked)} className='radio-btn' />
                                        <label className='addon-name'>{addon.addonName}</label>
                                    </div>
                                    <p className='addon-price'>Rs.{addon.addonPrice}</p>
                                </div>
                            ))}
                        </div>
                        }
                        {dishDetails.flavours.length > 0 && <div className="modal-addons">
                            <p className='addons-title'>Flavours</p>
                            {dishDetails.flavours.map((falvour) => (
                                <div className='addons-list'>
                                    <div className="radio-sec">
                                        <input type="radio" name='flavour' onChange={() => setFlavour(falvour)} className='radio-btn' />
                                        <label className='addon-name mb-2'>{falvour}</label>
                                    </div>
                                </div>
                            ))}
                        </div>
                        }
                        {dishDetails.sauces.length > 0 && <div className="modal-addons">
                            <p className='addons-title'>Sauces</p>
                            {dishDetails.sauces.map((sauce) => (
                                <div className='addons-list'>
                                    <div className="radio-sec">
                                        <input type="radio" name='sauce' onChange={() => setSauce(sauce)} className='radio-btn' />
                                        <label className='addon-name mb-2'>{sauce}</label>
                                    </div>
                                </div>
                            ))}
                        </div>
                        }
                        {dishDetails.crust.length > 0 && <div className="modal-addons mb-2">
                            <p className='addons-title'>Crust</p>
                            {dishDetails.crust.map((crust) => (
                                <div className='addons-list'>
                                    <div className="radio-sec">
                                        <input type="radio" name='crust' onChange={() => setCrust(crust)} className='radio-btn' />
                                        <label className='addon-name mb-2'>{crust}</label>
                                    </div>
                                </div>
                            ))}
                        </div>
                        }
                    </div>
                </div>

                <div className="bottom-bar">
                    <div className="quantity-handler">
                        <span onClick={decreaseQuantity}><LuMinusCircle /></span>
                        <p>{quantity}</p>
                        <span onClick={increaseQuantity}><LuPlusCircle /></span>
                    </div>
                    <div>
                        <button className="add-to-cart-btn" onClick={handleAddToCart}>Add To Cart</button>
                    </div>
                    <div>
                        <button className="view-cart-btn">View Cart</button>
                    </div>
                    <div>
                        <button className="price-btn">Rs.{total?.toFixed(2)}</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default DetailsPopup
