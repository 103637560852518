import HomePage from "./componentes/home/homepage";
import Footer from "./componentes/Footer/footer";
import '@fortawesome/fontawesome-free/css/all.css';
import Facebook from "./componentes/tracking/Facebook";
import LandingGif from "./componentes/landing-gif/landing-gif";


function App() {
  return (
    <div>
      <Facebook/>
      <LandingGif/>
      <HomePage />
      <Footer />
    </div>
  );
}

export default App;
