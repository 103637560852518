import "./restaurant-app.css";

const RestaurantSectionNew = () => {
  return (
    <div className="container resturant-sectionnew" id="about">
      <div className="row">
        <div className="col-md-6 col-lg-6">
          <div className="about-card">
            <div className="card-body">
              <ul>
                <li className="resturant-headings">ForunPoint Partners:</li>
                <p className="resturant-section-content">
                ForunPoint Partner is your ultimate tool for culinary success. Unlock a world of opportunities to boost your business, expand your customer base, and maximise revenue. With our intuitive app, efficiency meets innovation, allowing you to get your restaurant live in just 3 minutes. Manage orders, track sales, and monitor financial analytics in real-time. Experience the ForunPoint difference with innovative features such as real-time order tracking, customer reviews and ratings, and notification alerts. Register your restaurant on ForunPoint today and join us on this culinary journey. Your success is just one tap away with ForunPoint Partner.
                </p>
                <div className="link-block">
                  <p className="link-tile">Restaurant App</p>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.whetcloud.de_vour_chef"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="link-img"
                      src="./images/playstore.png"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/d-vour-chef/id1541482615"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="link-img"
                      src="./images/appstore.png"
                      alt=""
                    />
                  </a>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-5">
          <div className="about-card-content">
            <div className="card-body resturant-contant">
            <img
                className="resturant-sectionnew-img"
                src="./images/chefap-01.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantSectionNew;
