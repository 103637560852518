import React, { useRef, useEffect, useState } from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/footer';
import './restaurant-home.css';
import { IoIosStar, IoIosAdd } from "react-icons/io";
import CartComponent from '../home/cart/cart';
import { useParams } from "react-router-dom";
import { getAllDishes, getMe } from '../../shared/apis';
import DetailsPopup from '../dish-detail-popup/Dish-popup';
import Slider from 'react-slick';
import Spinner from 'react-bootstrap/Spinner';
import { settings4 } from './settings';
import Toast from 'react-bootstrap/Toast';
import zIndex from '@mui/material/styles/zIndex';

function RestaurantHome() {
    const sliderRef = useRef(null);
    const [dishes, setDishes] = useState([]);
    const [restaurant, setRestaurant] = useState({});
    const { id } = useParams();
    // const [coordinates] = useState([33.148150, -96.763130]);
    const [coordinates] = useState([31.3728262, 74.1592163]);
    const [cartItems, setCartItems] = useState([]);
    const [selectedDish, setSelectedDish] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [slidesToShow, setSlidesToShow] = useState(3);
    const [isLoading, setIsLoading] = useState(false);
    const set1 = settings4;
    const [showToast, setShowToast] = useState(false);
    const [showToastS, setShowToastS] = useState(false);

    const [scrollPosition, setScrollPosition] = useState(0);
    const [userLoc, setUserLoc] = useState({});
    const toastStyle = {
        position: 'fixed',
        top: `${scrollPosition}px`,
        right: '10px', // You can adjust the right position as needed
        zIndex: 1000, // Ensure it's above other elements
    };
    const horiSliderRef = useRef(null);
    const [startX, setStartX] = useState(null);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [currentTimings, setCurrentTimings] = useState([]);


    const searchParams = new URLSearchParams(window.location.search);
    const dishParamValue = searchParams.get('dish');

    const handleTouchStart = (event) => {
        setStartX(event.touches[0].clientX);
    };

    const handleTouchMove = (event) => {
        if (!startX) return;

        const currentX = event.touches[0].clientX;
        const scrollX = startX - currentX;
        sliderRef.current.scrollLeft = scrollLeft + scrollX;
    };

    const handleTouchEnd = () => {
        setStartX(null);
        setScrollLeft(sliderRef.current.scrollLeft);
    };
    const showToastFunction = () => {
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const init = async () => {
        setIsLoading(true);
        scrollToTop();
        let dishes = await getAllDishes(id);
        if (dishParamValue) {
            let dish = dishes?.data?.dishes.find(item => item._id === dishParamValue);
            if (dish) {
                setSelectedDish(dish);
                setIsModalOpen(true);
            }
        }
        let me = await getMe();
        const userAddress = JSON.parse(localStorage.getItem('UserAddress'));
        let latlng = {};
        if (me.data.user.location?.coordinates) {
            latlng.lat = me.data.user.location.coordinates[1];
            latlng.lng = me.data.user.location.coordinates[0];
        } else if (userAddress) {
            latlng.lat = userAddress.location.coordinates[1];
            latlng.lng = userAddress.location.coordinates[0];
        }
        setUserLoc(latlng);
        setDishes(dishes.data.dishes);
        setRestaurant(dishes.data.dishes[0].user);
        setIsLoading(false);
        console.log(dishes.data.dishes[0].user.slots);


        const now = new Date();

        const day = now.toLocaleDateString('en-US', { weekday: 'long' });
        const hour = now.getHours();
        const minute = now.getMinutes();
        const ampm = hour >= 12 ? 'PM' : 'AM';

        const formattedHour = hour % 12 || 12; // Convert to 12-hour format
        const formattedMinute = minute < 10 ? `0${minute}` : minute; // Add leading zero if needed
        dishes.data.dishes[0].user.slots.forEach(slot => {
            if (slot.day === day) {
                setCurrentTimings(slot.timings);
                console.log(slot.timings)

                slot.timings.forEach(time => {
                    console.log('Open', time.open)
                    console.log('Close', time.close)
                    let OpenTime = time?.open?.split(' ');
                    let CloseTime = time?.close?.split(' ');
                    let opentime = OpenTime[0]
                    let ampm1 = OpenTime[1]

                    let Closetime = CloseTime[0]
                    let ampm2 = CloseTime[1]

                    console.log(opentime)
                    console.log(ampm1);
                    console.log(Closetime)
                    console.log(ampm2)

                    let OpenM = opentime.split(':');
                    let CloseM = Closetime.split(':');
                    // console.log(OpenTime, CloseTime)
                    console.log(OpenM)
                    console.log(CloseM)

                    if (ampm1 === ampm && ampm2 === ampm) {
                        if (formattedHour >= OpenM && formattedHour <= CloseM) {
                            console.log('Restaurant is currently open.');
                        } else {
                            console.log('Restaurant is currently closed.');
                            setShowToastS(true);
                            // setTimeout(() => {
                            //     setShowToastS(false);
                            // }, 3000);
                        }
                    } else {
                        console.log('Restaurant is currently closed.');
                        setShowToastS(true);
                        // setTimeout(() => {
                        //     setShowToastS(false);
                        // }, 3000);
                    }

                });


            }
        });
        console.log(`Day: ${day}`);
        console.log(`Time: ${formattedHour}`);
        console.log(`${formattedMinute}`);
        console.log(`${ampm}`);

    };

    const openModal = (dish) => {
        setSelectedDish(dish);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedDish(null);
        setIsModalOpen(false);
    };

    useEffect(() => {
        init();
        const handleResize = () => {
            if (sliderRef.current) {
                const containerWidth = sliderRef.current.clientWidth;
                const newSlidesToShow = Math.floor(containerWidth / 300);
                setSlidesToShow(newSlidesToShow);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        init();
        const handleScroll = (event) => {
            setScrollPosition(window.pageYOffset);
            if (sliderRef.current && sliderRef.current.contains(event.target)) {
                sliderRef.current.scrollTop += event.deltaY;
            }
        };

        window.addEventListener('wheel', handleScroll);

        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
    }, []);

    // const addToCart = (item) => {
    //     const existingItem = cartItems.find((cartItem) => cartItem.id === item.id);
    //     if (existingItem) {
    //         setCartItems((prevCartItems) =>
    //             prevCartItems.map((cartItem) =>
    //                 cartItem.id === item.id
    //                     ? { ...cartItem, quantity: cartItem.quantity + 1 }
    //                     : cartItem
    //             )
    //         );
    //     } else {
    //         setCartItems((prevCartItems) => [...prevCartItems, item]);
    //     }
    // };
    // const addToCart = (item) => {
    //     setCartItems((prevCartItems) => {
    //         console.log(cartItems)
    //         const existingItem = prevCartItems.find(
    //             (cartItem) => cartItem.id === item.id && cartItem.size === item.size
    //         );

    //         if (existingItem) {
    //             return prevCartItems.map((cartItem) =>
    //                 cartItem.id === item.id && cartItem.size === item.size
    //                     ? { ...cartItem, quantity: cartItem.quantity + 1 }
    //                     : cartItem
    //             );
    //         } else {
    //             return [...prevCartItems, { ...item, quantity: 1 }];
    //         }
    //     });

    //     // Update localStorage after state update
    //     setTimeout(() => {
    //         localStorage.setItem('cartItems', JSON.stringify(cartItems));
    //     }, 600);
    // };
    const addToCart = (item) => {
        setCartItems((prevCartItems) => {

            // Check if there are items in the cart from a different restaurant
            if (prevCartItems.length > 0 && prevCartItems[0].restaurant !== item.restaurant) {
                alert('You can only add items from one restaurant at a time.');
                return prevCartItems; // Do not add the item if it's from a different restaurant
            }

            const existingItem = prevCartItems.find(
                (cartItem) => cartItem.id === item.id && cartItem.size === item.size
            );

            if (existingItem) {
                return prevCartItems.map((cartItem) =>
                    cartItem.id === item.id && cartItem.size === item.size
                        ? { ...cartItem, quantity: cartItem.quantity + 1 }
                        : cartItem
                );
            } else {
                return [...prevCartItems, { ...item, quantity: 1 }];
            }
        });

        // Update localStorage after state update
        setTimeout(() => {
            localStorage.setItem('cartItems', JSON.stringify(cartItems));
        }, 600);
        // console.log(cartItems);
        // console.log(cartItems[0].cartItems?.size);

    };



    const haversine = (lat1, lon1, lat2, lon2) => {
        lat1 = toRadians(lat1);
        lon1 = toRadians(lon1);
        lat2 = toRadians(lat2);
        lon2 = toRadians(lon2);

        let dlat = lat2 - lat1;
        let dlon = lon2 - lon1;

        let a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2;
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const R = 6371;
        let distance = R * c;
        return distance.toFixed(2);
    }

    const toRadians = (degrees) => {
        return degrees * (Math.PI / 180);
    }

    const getDishesByCategories = async (category) => {
        let dishes = await getAllDishes(id);
        setDishes(dishes.data.dishes);
        setDishes(dishes.data.dishes.filter(dish => dish.category === category));
    }

    const restaurantSearch = async (input) => {
        let dishes = await getAllDishes(id);
        setDishes(dishes.data.dishes);
        const regex = new RegExp(input, 'i');
        setDishes(dishes.data.dishes.filter(item => regex.test(item.dishName)));
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const renderStars = (rating) => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            if (i < rating) {
                stars.push(<IoIosStar key={i} className='star-ico' />);
            } else {
                stars.push(<IoIosStar key={i} className='star-ico' id='black-ico' />);
            }
        }
        return stars;
    };

    return (
        <div>
            <Navbar />
            {showToast &&
                <Toast style={toastStyle}>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">Error</strong>
                    </Toast.Header>
                    <Toast.Body>Sign In to continue.</Toast.Body>
                </Toast>
            }

            {/* {showToastS &&
                // <div className="custom-toast">
                //     <div className="custom-toast-header">
                //         <strong className="custom-toast-title">Error</strong>
                //         <button className="custom-toast-close">&times;</button>
                //     </div>
                //     <div className="custom-toast-body">
                //         Restaurant is currently close.

                //     </div>
                // </div>
                <p>Restaurant is currently close.</p>

            } */}



            <div className='restaurant-home'>
                <div class="restaurant-search">
                    <div class="row d-flex justify-content-center align-items-center">
                        <div class="col-md-8">
                            <div class="search-bar">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Restaurant Dishes"
                                    onChange={(e) => {
                                        restaurantSearch(e.target.value);
                                    }}
                                />
                                <button class="btn btn-primary" >
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading &&
                    <div className='mt-5' style={{ textAlign: 'center' }}>
                        <Spinner animation="border" variant="danger" />
                    </div>
                }
                {!isLoading &&
                    <div>
                        <div>
                            <div>
                                <img src={restaurant.coverPhoto} class="cover-img" />
                            </div>
                        </div>
                        <div className="restaurant-details">
                            <h1 className='restaurant-title'>{restaurant.restaurantName}</h1>
                            <p className="ratings">({restaurant.rating})
                                {renderStars(restaurant.rating)}
                            </p>
                            <p className='restaurant-distance'>Distance:&nbsp;
                                {haversine(userLoc.lat, userLoc.lng, restaurant?.location?.coordinates[1], restaurant?.location?.coordinates[0])} km
                            </p>
                            {showToastS &&

                                <p className='rest-close'>This Restaurant is Currently Closed.</p>

                            }
                            {restaurant.about &&
                                <p className="restaurant-description">
                                    <b>Restaurant Description: </b>
                                    {restaurant.about}
                                </p>
                            }
                        </div>
                        {/* <div className="special-deals">
                        <h1 className="deals-title">Special Featured Deals</h1>
                        <div className="special-section row">
                            <div>
                                <Slider {...set}>
                                    <div className="sd-card">
                                        <div className="special-card">
                                            <img src='./images/deal1.png' alt="" className="sd-img" />
                                        </div>
                                    </div>
                                    <div className="sd-card">
                                        <div className="special-card">
                                            <img src='./images/deal2.png' alt="" className="sd-img" />
                                        </div>
                                    </div>
                                    <div className="sd-card">
                                        <div className="special-card">
                                            <img src='./images/deal4.png' alt="" className="sd-img" />
                                        </div>
                                    </div>
                                    <div className="sd-card">
                                        <div className="special-card">
                                            <img src='./images/deal3.png' alt="" className="sd-img" />
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div> */}
                        <hr />
                        {/* <div className='category-deals-filter'>
                            <Slider {...set1}>
                                {restaurant?.categories?.map((category) => (
                                    <div className="cat-card">
                                        <button onClick={() => getDishesByCategories(category)} className='category-btns'>{category}</button>
                                    </div>
                                ))}
                            </Slider>
                        </div> */}

                        <div className="slider-container" ref={horiSliderRef} >
                            {restaurant?.categories?.map((category) => (
                                <div className="cat-card">
                                    <button onClick={() => getDishesByCategories(category)} className='category-btns'>{category}</button>
                                </div>
                            ))}
                        </div>
                        <hr className='hr-line' />
                        <div className="content-wrapper" >
                            <div className="myvertical-slider" ref={sliderRef} slidesToShow={slidesToShow}>

                                <div className="myslider-content category-deal-content">
                                    {dishes.map((dish) => (
                                        <div className="category-deal-card" onClick={() =>
                                            openModal(dish)
                                        }>
                                            <div className="plus-sign"><IoIosAdd className='plus-ico' /></div>
                                            <div className="image-container">
                                                <img src={dish.picture} className="category-deal-img" />
                                            </div>
                                            <div className="category-deal-card-body">
                                                <p className='food-nam'>{dish?.dishName?.substring(0, 15)}</p>
                                                {/* {dish.price && <p className='price-home'>Rs.{dish.price} </p>}
                                                {!dish.price && <p className='price-home'>Rs.{dish?.sizes[0].price}</p>} */}
                                                {dish?.price ? (
                                                    <p className='price-food'>Rs.{dish.price}</p>
                                                ) : (
                                                    <p className='price-food'>Rs.{dish?.sizes?.[0]?.price}</p>
                                                )}
                                            </div>
                                            {dish?.description?.length > 0 && <div className="category-description-card-body">
                                                {dish?.description?.substring(0, 30)}
                                            </div>
                                            }
                                        </div>
                                    ))}

                                </div>

                            </div>

                            <div>
                                <DetailsPopup
                                    isOpen={isModalOpen}
                                    onClose={closeModal}
                                    dishDetails={selectedDish}
                                    addToCart={addToCart}
                                />
                            </div>

                            <div className="cart-section">
                                <CartComponent showToast={showToastFunction} itemsInCart={cartItems} addToCart={addToCart} />
                            </div>
                        </div>

                    </div>
                }

            </div>
            <Footer />
        </div>
    )
}

export default RestaurantHome;
