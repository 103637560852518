// import React, { useState } from 'react';
// import { Modal } from 'react-responsive-modal';
// import LoginComponent from '../login/login';
// import './signup.css';
// import { sendVerificationEmail, signup } from '../../../shared/apis';

// const SignupComponent = ({ open, onCloseModal }) => {
//   const [openLoginComponent, setOpenLoginComponent] = useState(false);
//   const [email, setEmail] = useState('');
//   const [signupMode, setSignupMode] = useState('email');
//   const [verificationCode, setVerificationCode] = useState('');
//   const [firstName, setFirstName] = useState('');
//   const [lastName, setLastName] = useState('');
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState('');
//   const [user, setUser] = useState(null);
//   const [fpUser, setFpUser] = useState(null);


//   const handleLoginClick = () => {
//     setOpenLoginComponent(true);
//     onCloseModal();
//   };

//   const sendCode = async () => {
//     if (email) {
//       setError('')
//       setSignupMode('code');
//       const verificatioonCode = Math.floor(100000 + Math.random() * 900000);
//       localStorage.setItem("verificatioonCode", JSON.stringify(verificatioonCode));
//       let body = {
//         code: verificatioonCode,
//         email: email
//       }
//       let results = await sendVerificationEmail(body);
//       console.log(results);
//     } else {
//       setError('Enter email to continue.')
//     }
//   };

//   const verifyCode = async () => {
//     const code = localStorage.getItem("verificatioonCode");
//     if (verificationCode !== '') {
//       if (verificationCode === code) {
//         setError('')
//         setSignupMode('signup');
//         localStorage.removeItem('verificatioonCode');
//       }
//     } else {
//       setError('Verification code mismatch.')
//     }
//   };

//   const signupNow = async () => {
//     let body = {
//       firstName: firstName,
//       lastName: lastName,
//       email: email,
//       role: 'USER',
//       isEmailVerified: true,
//       password: password,
//       phoneNumber: phoneNumber,
//       deviceType: 'Android',
//     }
//     if (validateSignUp(body)) {
//       setError('');
//       let user = await signup(body);
//       console.log(user)
//       setUser(user.data.user);
//       setFpUser(user.data.FpUser);
//       localStorage.setItem("token", JSON.stringify(user.data.token));
//       localStorage.setItem("FpToken", JSON.stringify(user.data.FpUser.token));
//       onCloseModal();
//     } else {
//       setError('All fields are mandatory.')
//     }
//   };

//   const validateSignUp = (body) => {
//     for (const key in body) {
//       if (body.hasOwnProperty(key)) {
//         const value = body[key];
//         if (value === undefined || value === null || value === '') {
//           return false;
//         }
//       }
//     }
//     return true;
//   };

//   return (
//     <>
//       <div className="signup-modal">
//         <Modal open={open} onClose={onCloseModal} center styles={{
//           modal: {
//             width: '80%',
//             maxWidth: '400px',
//             height: 'auto',
//           }
//         }}>
//           {signupMode === 'email' &&
//             <>
//               <div className="signup-popup">

//                 <img src="./images/login-icon.png" className='signup-icon' alt="" />
//                 <br />
//                 <label htmlFor="Email" className='field-labels'>Email</label>
//                 <img className='login-icons3' src="./images/person-icon.png" alt="" />

//                 <input type="email" className='signup-fields' onChange={(e) => { setEmail(e.target.value) }} required />
//                 <br />
//                 <img src="./images/google-icon.png" className='social-icons' alt="" />
//                 <button className='login-btn-modal' onClick={() => sendCode()}>Send Code</button>
//                 <br />
//                 <button className='signup-btn-modal' onMouseDown={handleLoginClick}>Have an account? Sign In</button>
//                 {error && <div className='error-label'>{error}</div>}
//               </div>
//             </>
//           }
//           {signupMode === 'code' &&
//             <>
//               <div className="signup-popup">

//                 <img src="./images/login-icon.png" className='signup-icon' alt="" />
//                 <br />
//                 <label htmlFor="Code" className='field-labels'>Enter Code</label>
//                 <img className='login-icons4' src="./images/person-icon.png" alt="" />
//                 <img className='login-icons3' src="./images/code-icon.png" alt="" />

//                 <input type="text" className='signup-fields' onChange={(e) => { setVerificationCode(e.target.value) }} required />

//                 <img src="./images/google-icon.png" className='social-icons' alt="" />
//                 <button className='login-btn-modal' onClick={() => verifyCode()}>Verify</button>
//                 <br />
//                 {error && <div className='error-label'>{error}</div>}
//               </div>
//             </>
//           }
//           {signupMode === 'signup' &&
//             <>
//               <div className="signup-popup">
//                 <img src="./images/login-icon.png" className='signup-icon' alt="" />
//                 <br />
//                 <label htmlFor="Code" className='field-labels'>First Name</label>
//                 <input type="text" className='signup-fields signup-fields-verified' onChange={(e) => { setFirstName(e.target.value) }} required />

//                 <label htmlFor="Code" className='field-labels'>Last Name</label>
//                 <input type="text" className='signup-fields signup-fields-verified' onChange={(e) => { setLastName(e.target.value) }} required />

//                 <label htmlFor="Code" className='field-labels'>Phone Number</label>
//                 <input type="number" className='signup-fields signup-fields-verified' onChange={(e) => { setPhoneNumber(e.target.value) }} required />

//                 <label htmlFor="Code" className='field-labels'>Password</label>
//                 <input type="password" className='signup-fields signup-fields-verified' onChange={(e) => { setPassword(e.target.value) }} required />

//                 <button className='login-btn-modal' onClick={() => signupNow()}>Sign up</button>
//                 <br />
//                 {error && <div className='error-label'>{error}</div>}
//               </div>
//             </>
//           }
//         </Modal>
//       </div>
//       {openLoginComponent && (
//         <LoginComponent open={true} onCloseModal={() => setOpenLoginComponent(false)} />
//       )}
//     </>
//   );
// };

// export default SignupComponent;




import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { useNavigate } from 'react-router-dom';
import LoginComponent from '../login/login';
import './signup.css';
import { sendVerificationEmail, signup } from '../../../shared/apis';
import signupicon from '../../../assets/images/login-icon.png';
import personicon from '../../../assets/images/person-icon.png';
import googleicon from '../../../assets/images/google-icon.png';

const SignupComponent = ({ open, onCloseModal }) => {
  const [openLoginComponent, setOpenLoginComponent] = useState(false);
  const [email, setEmail] = useState('');
  const [signupMode, setSignupMode] = useState('email');
  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [user, setUser] = useState(null);
  const [fpUser, setFpUser] = useState(null);

  const handleLoginClick = () => {
    setOpenLoginComponent(true);
    onCloseModal();
  };

  const sendCode = async () => {
    if (!email) {
      setError('Please enter an email.');
    } else if (!validateEmail(email)) {
      setError('Invalid email.');
    } else {
      setError('');
      setSignupMode('code');
      const verificationCode = Math.floor(100000 + Math.random() * 900000);
      localStorage.setItem("verificationCode", JSON.stringify(verificationCode));
      let body = {
        code: verificationCode,
        email: email
      };
      let results = await sendVerificationEmail(body);
    }
  };


  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const verifyCode = async () => {
    const code = localStorage.getItem("verificationCode");
    if (verificationCode !== '') {
      if (verificationCode === code) {
        setError('')
        setSignupMode('signup');
        localStorage.removeItem('verificationCode');
      }
    } else {
      setError('Verification code mismatch.')
    }
  };

  const signupNow = async () => {
    let body = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      role: 'USER',
      isEmailVerified: true,
      password: password,
      phoneNumber: phoneNumber,
      deviceType: 'Android',
    }
    if (validateSignUp(body)) {
      setError('');
      let user = await signup(body);
      setUser(user.data.user);
      setFpUser(user.data.FpUser);
      localStorage.setItem("token", JSON.stringify(user.data.token));
      localStorage.setItem("FpToken", JSON.stringify(user.data.FpUser.token));
      onCloseModal();
    } else {
      setError('All fields are mandatory.')
    }
    navigate('/restaurants');
  };

  const validateSignUp = (body) => {
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        const value = body[key];
        if (value === undefined || value === null || value === '') {
          return false;
        }
      }
    }
    return true;
  };

  return (
    <>
      <div className="signup-modal">
        <Modal open={open} onClose={onCloseModal} center styles={{
          modal: {
            width: '80%',
            maxWidth: '400px',
            height: 'auto',
          }
        }}>
          {signupMode === 'email' &&
            <>
              <div className="signup-popup">

                <img src={signupicon} className='signup-icon' alt="" />
                <br />
                <label htmlFor="Email" className='field-labels'>Email</label>
                <img className='login-icons3' src={personicon} alt="" />

                <input type="email" className='signup-fields' onChange={(e) => { setEmail(e.target.value) }} required />
                <br />
                {/* <img src={googleicon} className='social-icons' alt="" /> */}
                <button className='login-btn-modal dialog-btn' onClick={() => sendCode()}>Send Code</button>
                <br />
                <button className='signup-btn-modal dialog-btn' onMouseDown={handleLoginClick}>Have an account? Sign In</button>
                {error && <div className='error-message'>{error}</div>}
              </div>
            </>
          }
          {signupMode === 'code' &&
            <>
              <div className="signup-popup">

                <img src={signupicon} className='signup-icon' alt="" />
                <br />
                <label htmlFor="Code" className='field-labels'>Enter Code</label>
                <img className='login-icons4' src={personicon} alt="" />
                {/* <img className='login-icons3' src="./images/code-icon.png" alt="" /> */}

                <input type="text" className='signup-fields' onChange={(e) => { setVerificationCode(e.target.value) }} required />

                {/* <img src="./images/google-icon.png" className='social-icons' alt="" /> */}
                <button className='login-btn-modal dialog-btn' onClick={() => verifyCode()}>Verify</button>
                <br />
                {error && <div className='error-label error-text-red'>{error}</div>}
              </div>
            </>
          }
          {signupMode === 'signup' &&
            <>
              <div className="signup-popup">
                <img src="./images/login-icon.png" className='signup-icon' alt="" />
                <br />
                <label htmlFor="Code" className='field-labels'>First Name</label>
                <input type="text" className='signup-fields signup-fields-verified' onChange={(e) => { setFirstName(e.target.value) }} required />

                <label htmlFor="Code" className='field-labels'>Last Name</label>
                <input type="text" className='signup-fields signup-fields-verified' onChange={(e) => { setLastName(e.target.value) }} required />

                <label htmlFor="Code" className='field-labels'>Phone Number</label>
                <input type="number" className='signup-fields signup-fields-verified' onChange={(e) => { setPhoneNumber(e.target.value) }} required />

                <label htmlFor="Code" className='field-labels'>Password</label>
                <input type="password" className='signup-fields signup-fields-verified' onChange={(e) => { setPassword(e.target.value) }} required />

                <button className='login-btn-modal dialog-btn' onClick={() => signupNow()}>Sign up</button>
                <br />
                {error && <div className='error-label error-text-red'>{error}</div>}
              </div>
            </>
          }
        </Modal>
      </div>
      {openLoginComponent && (
        <LoginComponent open={true} onCloseModal={() => setOpenLoginComponent(false)} />
      )}
    </>
  );
};

export default SignupComponent;
