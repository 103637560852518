import React from "react";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/footer";
import { FiSearch } from "react-icons/fi";
import Slider from "../slider/slider";
import "./blog.css";

function blog() {
  const videos = [
    {
      id: 1,
      title: "Video 1 Title",
      description: "Description for Video 1",
      imageUrl: "https://via.placeholder.com/150", // Placeholder image URL
    },
    // Add more video objects here...
  ];
  return (
    <div>
      <Navbar />
      <div>
        <div class="blog-container">
          <div class="row height d-flex justify-content-center align-items-center">
            <div class="col-md-8">
              {/* <div class="search">
                <i class="fa fa-search"></i>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search..."
                />
                <button class="btn btn-primary">
                  <FiSearch className="icon" />
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* <div>
        <div className="blog-section-card">
          <Card className="blog-card">
            <img src="./images/update-img-2.jpg" className="blog-img-1" />
          </Card>
        </div>
        <div className="blog-menu-card">
        <Card className="blog-menu">
          <img src="./images/update-img-2.jpg" className='blog-menu-img' />
        </Card>
        </div>
       
      </div> */}

      <div class="blog blog-main container">
        <div class="row">
          <div class="col-md-6 col-lg-8">
            <div class="dvour-solution-card">
              <div class="card-body">
                <a href="/secondpage">
                  <div className="block-img-container">
                    <img src="./images/Gift.jpg" className="blog-img-1" />
                  </div>
                </a>
              </div>
            </div>
          </div>
          {/* <div class="col-md-6 col-lg-4">
            <div class="dvour-solution-card">
              <div class="card-body">
                <div className="side-menu">
                  {videos.map((video) => (
                    <div key={video.id} className="video-card">
                      <img
                        src={video.imageUrl}
                        alt={video.title}
                        className="video-thumbnail"
                      />
                      <div className="video-info">
                        <h3 className="video-title">{video.title}</h3>
                        <p className="video-description">{video.description}</p>
                      </div>
                    </div>
                  ))}
                  {videos.map((video) => (
                    <div key={video.id} className="video-card">
                      <img
                        src={video.imageUrl}
                        alt={video.title}
                        className="video-thumbnail"
                      />
                      <div className="video-info">
                        <h3 className="video-title">{video.title}</h3>
                        <p className="video-description">{video.description}</p>
                      </div>
                    </div>
                  ))}
                  {videos.map((video) => (
                    <div key={video.id} className="video-card">
                      <img
                        src={video.imageUrl}
                        alt={video.title}
                        className="video-thumbnail"
                      />
                      <div className="video-info">
                        <h3 className="video-title">{video.title}</h3>
                        <p className="video-description">{video.description}</p>
                      </div>
                    </div>
                  ))}
                  {videos.map((video) => (
                    <div key={video.id} className="video-card">
                      <img
                        src={video.imageUrl}
                        alt={video.title}
                        className="video-thumbnail"
                      />
                      <div className="video-info">
                        <h3 className="video-title">{video.title}</h3>
                        <p className="video-description">{video.description}</p>
                      </div>
                    </div>
                  ))}
                  {videos.map((video) => (
                    <div key={video.id} className="video-card">
                      <img
                        src={video.imageUrl}
                        alt={video.title}
                        className="video-thumbnail"
                      />
                      <div className="video-info">
                        <h3 className="video-title">{video.title}</h3>
                        <p className="video-description">{video.description}</p>
                      </div>
                    </div>
                  ))}
                  {videos.map((video) => (
                    <div key={video.id} className="video-card">
                      <img
                        src={video.imageUrl}
                        alt={video.title}
                        className="video-thumbnail"
                      />
                      <div className="video-info">
                        <h3 className="video-title">{video.title}</h3>
                        <p className="video-description">{video.description}</p>
                      </div>
                    </div>
                  ))}
                  {videos.map((video) => (
                    <div key={video.id} className="video-card">
                      <img
                        src={video.imageUrl}
                        alt={video.title}
                        className="video-thumbnail"
                      />
                      <div className="video-info">
                        <h3 className="video-title">{video.title}</h3>
                        <p className="video-description">{video.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div class="row blog-extra">
          <div class="col-md-6 col-lg-4">
            <div class="blog-card2">
              <div class="card-body">
                <a href="/firstpage">
                  <img
                    className="blog-section-img blog-section-img2"
                    src="./images/Voice.jpg"
                    alt=""
                  />
                </a>
              </div>
            </div>
            {/* <p className="blog-subtitle">How to install ForunPoint app? Demo Title</p> */}
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="blog-card2">
              <div class="card-body">
                <a href="/thirdpage">
                  <img
                    className="blog-section-img blog-section-img3"
                    src="./images/third.jpeg"
                    alt=""
                  />
                </a>
              </div>
            </div>
            {/* <div className="blog-card-title">
              How to install ForunPoint app? Demo Title
            </div> */}
          </div>
        </div>
      </div>
      <div className="announcements">
        <h2 className="announcement-title">Official Announcements</h2>
        <Slider />
        <p className="container announcement-desc">
          Looking ahead, we are excited to continue this trajectory of growth and innovation, further solidifying our commitment to providing unparalleled service to our customers and partners.


          <br /><br />
          Thank you to our dedicated team, loyal customers, and supportive partners for making 2024 a truly successful year for ForunPoint.
        </p>
        <br />
        <br />
      </div>
      <Footer />
    </div>
  );
}

export default blog;
