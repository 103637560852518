// import "./customer-app.css";

// const RestaurantSection = () => {
//   return (
//     <div className="resturant-div">
//       <div className="container resturant-section" id="about">
//         <div className="row">
//           <div className="col-md-6 col-lg-5">
//             <div className="about-card">
//               <div className="card-body">
//                 <img
//                   className="resturant-section-img"
//                   src="./images/resturant-section-img.png"
//                   alt=""
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="col-md-6 col-lg-6">
//             <div className="about-card-content">
//               <div className="card-body resturant-contant">
//                 <ul>

//                   <li className="resturant-headings">ForunPoint App:</li>
//                   <p className="resturant-section-content">
//                     Experience a world of culinary delights at your fingertips with the ForunPoint app! Whether you're craving cheesy pizza, juicy burgers, or fiery chicken tenders, we've got you covered with fast and reliable deliveries. Our app includes innovative features like gift giving, voice ordering, and optimised search results, ensuring you find exactly what you're looking for with ease. Track your order in real-time and enjoy exclusive deals from top restaurants. Don't miss out – download the ForunPoint app now and start enjoying delicious food delivered straight to your door!
//                   </p>
//                   {/* <button type="button" class="btn btn-danger resturant-btn"><a href="#home" className="install-btn">
//                     Install
//                   </a></button> */}
//                   <div className="link-block">
//                     <p className="link-tile">Customer App</p>
//                     <a
//                       href="https://play.google.com/store/apps/details?id=com.whetcloud.forunpoint.user"
//                       target="_blank"
//                       rel="noreferrer"
//                     >
//                       <img
//                         className="link-img"
//                         src="./images/playstore.png"
//                         alt=""
//                       />
//                     </a>

//                     <a
//                       href="https://apps.apple.com/us/app/forunpoint/id1630196197"
//                       target="_blank"
//                       rel="noreferrer"
//                     >
//                       <img
//                         className="link-img"
//                         src="./images/appstore.png"
//                         alt=""
//                       />
//                     </a>
//                   </div>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RestaurantSection;



import "./customer-app.css";

const RestaurantSection = () => {
  return (
    <div className="resturant-div">
      <div className="container resturant-section" id="about">
        <div className="row">
          <div className="col-md-6 col-lg-5 order-md-1">
            <div className="about-card">
              <div className="card-body">
                <img
                  className="resturant-section-img"
                  src="./images/resturant-section-img.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 order-md-2">
            <div className="about-card-content">
              <div className="card-body resturant-contant">
                <ul>
                  <li className="resturant-headings">ForunPoint App:</li>
                  <p className="resturant-section-content">
                    Experience a world of culinary delights at your fingertips with the ForunPoint app! Whether you're craving cheesy pizza, juicy burgers, or fiery chicken tenders, we've got you covered with fast and reliable deliveries. Our app includes innovative features like gift giving, voice ordering, and optimized search results, ensuring you find exactly what you're looking for with ease. Track your order in real-time and enjoy exclusive deals from top restaurants. Don't miss out – download the ForunPoint app now and start enjoying delicious food delivered straight to your door!
                  </p>
                  <div className="link-block">
                    <p className="link-tile">Customer App</p>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.whetcloud.forunpoint.user"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="link-img"
                        src="./images/playstore.png"
                        alt=""
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/us/app/forunpoint/id1630196197"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="link-img"
                        src="./images/appstore.png"
                        alt=""
                      />
                    </a>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantSection;
