import './order-details.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/footer';
import React, { useState, useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import CartComponent from '../home/cart/cart';
import { Modal } from 'react-responsive-modal';
import { getMe, createPaymentMethod, attachPaymentMethodToCustomer, getpaymentMethod, updateUser, detachPaymentMethod, getCouponDetails } from '../../shared/apis';
import Spinner from 'react-bootstrap/Spinner';
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import TextInput from 'react-autocomplete-input';
// import 'react-autocomplete-input/dist/bundle.css';
// import { Typeahead } from 'react-bootstrap-typeahead';
// import { Form } from 'react-bootstrap';
import Autocompletend from '../home/Autocomplete-nd/Autocompletend';
import axios from 'axios';



// import { getMe, createPaymentMethod, attachPaymentMethodToCustomer } from '../../shared/apis';
// import Autocomplete from './auto-complete/auto-complete';

function Checkout() {
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [loginActive, setLoginActive] = useState(true);
  const [open, setOpen] = useState(false);
  const [isApplyButtonPressed, setIsApplyButtonPressed] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [buttonColor, setButtonColor] = useState('rgb(51, 51, 51)');
  const [cartItems, setCartItems] = useState();
  const [isAddressButtonPressed, setIsAddressButtonPressed] = useState(false);
  const [isCloseButtonPressed, setIsCloseButtonPressed] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [addressInput, setAddressInput] = useState('');
  const [activeButton, setActiveButton] = useState(null);
  const [cartItem, setCartItem] = useState();
  const [doneButtonClick, setDoneButtonClick] = useState(false);
  const [user, setUser] = useState(null);
  const [accordionList, setAccordionList] = useState([]);
  const [addressDetails, setAddressDetails] = useState({
    houseNumber: '',
    noteToRider: ''
  });
  const [additionalAccordions, setAdditionalAccordions] = useState([]);
  const [userEnteredAccordions, setUserEnteredAccordions] = useState([]);
  // const [accordionToDelete, setAccordionToDelete] = useState(null);
  // const [checkoutData, setCheckoutData] = useState('');
  const [address, setAddress] = useState('');
  const [house, setHouse] = useState('');
  const [noterider, setNoterider] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [date, setDate] = useState('');
  const [cvc, setCvc] = useState('');
  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [restaurant, setRestaurant] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [open3, setOpen3] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelivery, setIsDelivery] = useState(true);
  const [selectedPM, setSelectedPM] = useState('Cod');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [formData, setFormData] = useState('')



  // Function to format the date in YYYYMMDDHHMMSS format
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  };

  // Function to generate a random number between 10 and 100
  const generateRandomNumber = () => {
    return Math.floor(Math.random() * (100 - 10 + 1)) + 10;
  };


  const handleOptionChange = (event) => {
    setSelectedPM(event.target.value);
    console.log(selectedPM)
  };
  useEffect(() => {


    fetchData();
    const storedObjectString = localStorage.getItem('cartItems');
    // console.log(storedObjectString)
    if (storedObjectString) {
      const retrievedObject = JSON.parse(storedObjectString)
      setCartItem(retrievedObject);
      setRestaurant(retrievedObject[0]?.cartItems?.dish?.user);
      if (retrievedObject[0].orderType === "DELIVERY") {
        setIsDelivery(true);

      }
      else if (retrievedObject[0].orderType === "PICKUP") {
        setIsDelivery(false);

      }

    }
  }, []);

  const addAdditionalAccordion = () => {
    // Reset address details
    setAddressDetails({ houseNumber: '', noteToRider: '' });

    // Create a new accordion with null content
    const newAccordion = { id: Date.now(), content: null };
    setAdditionalAccordions((prevList) => [...prevList, newAccordion]);


  };

  // const fetchData = async () => {
  //   const userProfile = await getMe();
  //   setUser(userProfile.data.user);
  //   console.log(userProfile);

  //   const pm = await Promise.all(
  //     userProfile.data.user.paymentMethods.map(async (paymentMethod) => {
  //       const paymentMethodData = await getpaymentMethod(paymentMethod);
  //       return paymentMethodData;
  //     })
  //   );

  //   setPaymentMethods(pm);
  //   console.log(pm)
  // };

  const fetchData = async () => {
    setIsLoading(true); // Set isLoading to true before fetching data
    try {
      const userProfile = await getMe();
      setUser(userProfile.data.user);
      const pm = await Promise.all(
        userProfile.data.user.paymentMethods.map(async (paymentMethod) => {
          const paymentMethodData = await getpaymentMethod(paymentMethod);
          return paymentMethodData;
        })
      );

      setPaymentMethods(pm);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false); // Set isLoading to false after data fetching is complete
    }
  };

  const removePayment = async (pmId) => {
    try {
      setSubmitted(true);
      const updatedPaymentMethods = paymentMethods.filter((pm) => pm.paymentMethod.id !== pmId);
      setPaymentMethods(updatedPaymentMethods);
      await detachPaymentMethod(pmId);
      setOpen3(false);
    } catch (error) {
      console.error('Error removing payment method:', error);
    }
  };

  const OpenModal3 = () => {
    setOpen3(true);
  };

  const onCloseModal3 = () => {
    setOpen3(false);
  };

  const selectPaymentMethod = (pm_Id) => {
    setSelectedPM('Card');
    setPaymentMethodId(pm_Id);
  }

  const renderCardList = () => {
    if (!paymentMethods) {
      return null; // Or you can return a loading indicator
    }
    return paymentMethods.map((pm, index) => (
      <div className="final-payment-method" key={index}>
        <img src="./images/genral-card.png" alt="" className='final-genral-card' />
        <h3 className='final-card-number2'>************{pm?.paymentMethod.card?.last4}</h3>
        <input
          type="radio"
          name="selectedPaymentMethod"
          value={pm.paymentMethod.id}
          className='payment-radio-btn'
          onClick={() => { selectPaymentMethod(pm.paymentMethod.id) }}
        />
        <Modal open={open3} onClose={onCloseModal3} center styles={{
          modal: {
            width: '80%',
            maxWidth: '400px',
            height: '130px',
            backgroundColor: '#E2E2E2'
          },

          overlay: {
            background: 'rgba(0, 0, 0, 0.159)'
          }
        }}>

          <p className='remove-question'>Are You Sure You want to Remove?</p>
          {/* <button onClick={() => removePayment(pm.paymentMethod.id)} className='yes-button'>Yes</button> */}
          <button onClick={() => removePayment(pm.paymentMethod.id)} className={`yes-button  ${submitted ? 'disabled' : ''}`} disabled={submitted}>Yes</button>
          <button onClick={onCloseModal3} className='no-button'>No</button>

        </Modal>
      </div>

    ));
    // });
  };


  const handleAddressSubmit = (accordionId) => {
    handleAccordionDelete(accordionId);

    const newAccordion = {
      id: Date.now(),
      content: (
        <>

        </>
      ),
    };
    const updatedAdditionalAccordions = additionalAccordions.filter(
      (accordion) => accordion.id !== accordionId
    );
    const updatedUserEnteredAccordions = userEnteredAccordions.filter(
      (accordion) => accordion.id !== accordionId
    );

    setAdditionalAccordions(updatedAdditionalAccordions);
    setUserEnteredAccordions(updatedUserEnteredAccordions);
    setAccordionList((prevList) => [...prevList, newAccordion.content]);

  };
  const handleAccordionDelete = (accordionId) => {
    // Remove the accordion from additionalAccordions state
    setAdditionalAccordions((prevList) =>
      prevList.filter((accordion) => accordion.id !== accordionId)
    );

    // Remove the corresponding user-entered accordion from userEnteredAccordions state
    setUserEnteredAccordions((prevList) =>
      prevList.filter((accordion) => accordion.id !== accordionId)
    );

    // Remove the accordion content from accordionList state
    setAccordionList((prevList) =>
      prevList.filter((accordion) => accordion.key !== accordionId)
    );
  };

  const handleUserCreatedAccordion = (accordionId) => {
    setAccordionList((prevList) =>
      prevList.filter((accordion) => accordion.id !== accordionId)
    );

  };



  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleEditAddressButtonClick = () => {
    // Handle opening modal for editing address
    setModalOpen(true);
  };

  const handleAddressInputChange = (e) => {
    setAddressInput(e.target.value);
  };

  const handleLocationSelection = (location) => {
    // Reset styles for previously selected location
    if (selectedLocation) {
      document.getElementById(selectedLocation).style.backgroundColor = '';
      document.getElementById(selectedLocation).style.color = '';
    }

    // Set styles for the newly selected location
    document.getElementById(location).style.backgroundColor = '#d4110f';
    document.getElementById(location).style.color = '#FFFFFF';
    // Set selected location
    setSelectedLocation(location);
    setActiveButton(location);
  };

  const addToCart = (item) => {
    const existingItem = cartItems.find((cartItem) => cartItem.id === item.id);

    if (existingItem) {
      setCartItems((prevCartItems) =>
        prevCartItems.map((cartItem) =>
          cartItem.id === item.id
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        )
      );
    } else {
      setCartItems((prevCartItems) => [...prevCartItems, item]);
    }
    setTimeout(() => {
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, 700);
  };


  const handleapplyButtonClick = () => {
    // Additional logic if needed
    setIsApplyButtonPressed(true);
  };

  const handleLoginClick = () => {
    setLoginActive(true);
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
    setLoginActive(true);
    setIsApplyButtonPressed(false);
    setInputValue('');
    setButtonColor('rgb(51, 51, 51)');
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    // Check if the entered value is a number
    if (!isFinite(value) | !isNaN(value) && value !== '') {
      setButtonColor('#d4110f'); // Change button color to green if it's a number
    } else {
      setButtonColor('rgb(51, 51, 51)'); // Change button color to green if it's a number
    }
  }

  const handleAddressButtonClick = () => {
    // Additional logic if needed
    setIsAddressButtonPressed(true);
  };

  const handleCloseButton = () => {
    // Additional logic if needed
    setIsCloseButtonPressed(true);
    setIsAddressButtonPressed(false);
  };

  const handleCouponApplied = async () => {
    const coupons = await getCouponDetails(inputValue);
    onCloseModal()
    setDoneButtonClick(true);
  }

  const checkout = async () => {
    try {
      setIsButtonClicked(true);
      let card = {
        number: cardNumber,
        exp_month: date.split('-')[1],
        exp_year: date.split('-')[0],
        cvc: cvc
      };
      if (user.stripeCustomerId) {
        let pm = await createPaymentMethod(card);
        let paymentMethods = user.paymentMethods
        paymentMethods.push(pm.paymentMethod.id)
        setPaymentMethodId(pm.paymentMethod.id)
        await attachPaymentMethodToCustomer({ paymentMethodId: pm.paymentMethod.id, customerId: user.stripeCustomerId });
        await updateUser(user._id, { paymentMethods: paymentMethods })
        fetchData();
        renderCardList();
        setIsButtonClicked(false);
      }
    } catch (error) {
      console.error('Error adding payment method:', error);
      setIsButtonClicked(false);
    }

  };
  // const initiatePayment = async () => {
  //   try {
  //     let fromData = {
  //       date_default_timezone_set: 'Asia/karachi',
  //       pp_Amount: '100',
  //       pp_BankID: '',
  //       pp_BillReference: '10000',
  //       pp_Description: 'Description of test transaction',
  //       pp_Language: 'EN',
  //       pp_MerchantID: 'MC74326',
  //       pp_Password: '19szy79wtx',
  //       pp_ProductID: '',
  //       pp_ReturnURL: 'https://www.forunpoint.com',
  //       pp_TxnCurrency: 'PKR',
  //       pp_TxnDateTime: '',
  //       pp_TxnExpiryDateTime: '',
  //       pp_TxnRefNo: '',
  //       pp_TxnType: 'MPAY',
  //       pp_Version: '1.1',
  //       ppmpf_1: '',
  //       ppmpf_2: '',
  //       ppmpf_3: '',
  //       ppmpf_4: '',
  //       ppmpf_5: '',
  //       pp_SubMerchantID: '',
  //     }
  //     const IntegritySalt = 'u51y1sy041';


  //     const hashString =
  //       IntegritySalt +
  //       '&' +
  //       Object.values(fromData)
  //         .filter((value) => value !== '')
  //         .join('&');
  //     const domainName = 'forunpoint.com.pk';
  //     const domainPrefix = domainName.slice(0, 3).toUpperCase();

  //     // Get the current date and time
  //     const currentDateTime = new Date();
  //     const formattedCurrentDateTime = formatDate(currentDateTime);
  //     fromData.pp_TxnDateTime = formattedCurrentDateTime
  //     // Calculate the expiry date and time (8 days from now)
  //     const expiryDateTime = new Date();
  //     expiryDateTime.setDate(expiryDateTime.getDate() + 8);
  //     const formattedExpiryDateTime = formatDate(expiryDateTime);
  //     fromData.pp_TxnExpiryDateTime = formattedExpiryDateTime
  //     // Generate the random number
  //     const randomNumber = generateRandomNumber();
  //     // Create the TxnRefNumber
  //     const TxnRefNumber = `${domainPrefix}${formattedCurrentDateTime}${randomNumber}`;
  //     fromData.pp_TxnRefNo = TxnRefNumber;
  //     // console.log(hashString);
  //     // console.log(TxnRefNumber);
  //     // console.log(fromData);

  //     fromData.pp_SecureHash = hashString;
  //     // const response = await axios.post('https://sandbox.jazzcash.com.pk/CustomerPortal/transactionmanagement/merchantform/', fromData);
  //     // const response = await axios.post('/api/CustomerPortal/transactionmanagement/merchantform/', fromData);
  //     // const response = await jazzcash(fromData);



  //     if (response.data.status === 'success') {
  //       setPaymentStatus('Payment successful!');
  //     } else {
  //       setPaymentStatus('Payment failed.');
  //     }
  //   } catch (error) {
  //     console.error('Error initiating payment:', error);
  //     setPaymentStatus('Payment failed.');
  //   }





  //   console.log()
  // };


  return (
    <>
      <Navbar />
      <div className="main-order-body">
        <div className="order-section">
          <div className="order-sec-details">
            <h2 className='order-sec-title'>Order From:</h2>
            <p className='order-sec-desc'>{restaurant?.restaurantName}</p>
          </div>

          <div className="order-details">
            <div className="order-content">

              {/* <Form.Group>
                <Form.Label>Enter Address</Form.Label>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  onChange={setSingleSelections}
                  options={options}
                  placeholder="Address..."
                  selected={singleSelections}
                />
              </Form.Group> */}
              {isDelivery && (<div>
                <Autocompletend />
              </div>
              )}


              <div className="order-cards">
                <div className="payment-method-section">
                  <div className="payment-method-2">
                    <img src="./images/payment_wallet.png" className='payment-wallet-2' alt="" />
                    <p className='payment-method-text-2'>Payment Methods:</p>
                  </div>
                  {isLoading ? (
                    <div className="slider-content d-flex justify-content-center align-items-center">
                      <div className='m-4' style={{ textAlign: 'center' }}>
                        <Spinner animation="border" variant="danger" />
                      </div>
                    </div>
                  ) : (
                    renderCardList()
                  )}
                </div>
              </div>
              <div className="order-cards">
                <div className="card-body payment-content-body">
                  <div className="card-top-content">
                    <h2 className="order-card-title">Payment Details</h2>
                    {/* <p className='change-btn'>Change</p> */}
                  </div>
                  <div className="payment-container">
                    <div id="accordion">
                      {/* <div className='paypal-containers'>
                        <input type="radio" name="accordion-group" id="option-1" className='input' />
                        <label for="option-1" className='label'><p>PayPal</p><img src="/images/image 19.png" alt="" className='paypal' /></label>
                        <article className='paypal-section'>
                          <p className='paypal-title'>Use Paypal as payment method for your order</p>
                          <p className='paypal-desc'>Enjoy the convenience of paying the Point Rider directly from the website</p>
                        </article>
                      </div> */}
                      <div className='paypal-containers'>
                        {/* <input type="radio" name="accordion-group" id="option-1" className='input'  /> */}
                        <input
                          type="radio"
                          name="accordion-group"
                          id="option-1"
                          className='input'
                          value="Cod" // Set the value you want to return
                          onChange={handleOptionChange} // Call the function when the input changes
                        />
                        <label for="option-1" className='label'><p>Cash on Delivery</p><img src="/images/cod.jpg" alt="" className='paypal' /></label>
                      </div>
                      {/* jazzcash */}
                      <div className='paypal-containers'>
                        {/* <input type="radio" name="accordion-group" id="option-1" className='input'  /> */}
                        {/* <input
                          type="radio"
                          name="accordion-group"
                          id="option-3"
                          className='input'
                          value="jazzcash" // Set the value you want to return
                          onChange={handleOptionChange} // Call the function when the input changes
                        /> */}
                        <label for="option-3" className='label c-and-d-card'><p>Credit or Debit Card (Coming soon)</p>
                          {/* <img src="/images/cod.jpg" alt="" className='paypal' /> */}
                        </label>
                      </div>
{/* 
                      <div>
                        <button onClick={initiatePayment}>Initiate Payment</button>

                        <p>{paymentStatus}</p>
                      </div> */}

                      <div className='cred-or-debit'>
                        {/* <input type="radio" name="accordion-group" id="option-2" className='input' />
                        <label for="option-2" className='label'><p>Credit or Debit Card</p><img src="/images/image 20.png" alt="" className='credit-cards' /></label> */}
                        <article className='creditpart'>
                          {/* <input type="text" placeholder='Name on Card' className='cardname' /> */}
                          <div className="input-content">
                            <input
                              type="text"
                              placeholder='Card Number'
                              className='cardnum'
                              maxLength={16}
                              minLength={8}
                              value={cardNumber}
                              onChange={(e) => {
                                const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                                if (value.length <= 16) {
                                  setCardNumber(value);
                                }
                              }}
                            />

                            <input type="month" placeholder='MM/YY' className='date' onChange={(e) => { setDate(e.target.value) }} />
                            <input
                              type="number"
                              placeholder='CvC'
                              className='cvc accord-input'
                              value={cvc}
                              onChange={(e) => {
                                const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                                if (value.length <= 3) {
                                  setCvc(value);
                                }
                              }}
                            />

                          </div>
                          <button
                            onClick={checkout}
                            className={`add-pyment-btn ${isButtonClicked ? 'disabled' : ''}`}
                            style={{ backgroundColor: isButtonClicked ? 'grey' : '#d4110f' }}
                          >
                            Add Payment Method
                          </button>

                          {/* <br />
                        <input type="checkbox" id='rem' className='credit-checkbox' />
                        <label for="rem" className='checkbox-label'>Keep this card on file for quicker checkout during your next order.</label> */}
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
{/* coupon commented */}
              {/* <div className="coupon">
                <img src="/images/icon _coupon_.png" alt="" height='25px' />
                {doneButtonClick == true ? (
                  <p onMouseDown={handleLoginClick}>Coupon Applied</p>
                ) : (
                  <p onMouseDown={handleLoginClick}>Apply Coupon</p>
                )}
              </div> */}

              <div className="container-fluid">
                <div>
                  <div className="navbar-nav ms-auto">
                    <div className='modal'>
                      <Modal open={open} onClose={onCloseModal} center styles={{
                        modal: {
                          width: '70%',
                          maxWidth: '300px',
                          height: '350px',
                        },
                      }}>
                        {/* <>
                          <p className='coupon-title'>Apply Coupon Code</p>
                          <br />
                          <img src="./images/voucher-icon.png" alt="" className='coupon-icon-2' />
                          <input type="text" className='couponcode2' value={inputValue} onChange={handleInputChange} required />
                          <p className='tc2'>Terms and Conditions<p className='applied2'> are applied</p></p>
                          <div className="btn-sec">
                            <button className='done-btn-2'>Done</button>
                          </div>
                        </> */}

                        <>
                          <p className='coupon-title' style={{ color: buttonColor }}>Apply Coupon Code</p>
                          <br />
                          <img src="./images/voucher-icon.png" alt="" className='coupon-icon' />
                          <input type="text" className='couponcode' value={inputValue} onChange={handleInputChange} required />
                          <p className='tc'>Terms and Conditions<p className='applied'>  are applied</p></p>
                          <img src="./images/coupon-img.png" className='couponimg' alt="" />
                          <div className="btn-sec">
                            <button className='done-btn' style={{ backgroundColor: buttonColor }} onClick={handleCouponApplied}>Done</button>
                          </div>
                          <br />
                        </>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div className="cart-component">
              <CartComponent itemsInCart={cartItem} pmId={paymentMethodId} paymentBy={selectedPM} addToCart={addToCart} />
            </div>
          </div >
        </div >
      <Footer/>
      </div >
    </>
  );
}

export default Checkout;