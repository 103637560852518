import Navbar from "../../Navbar/Navbar";
import CookieConsent from "../../cookies-consent/cookies-consent";
import "./LandingHome.css";
import Autocomplete from '../../home/Autocomplete/Autocomplete';
const LandingHome = () => {
  return (
    <div class="landing-home-container">
      <Navbar />
      <div class="row">
        <div class="col-md-6 col-lg-5">
          <div class="landing-card">
            <div class="card-body">
              <h2 className="landing-heading">Your Desire One Tap Away</h2>
              <p className="landing-desc">Add location where you want to deliver</p>

              <div class="input-group md-form form-sm form-2 pl-0">
                <Autocomplete />
                <div class="input-group-append">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-7">
          <div class="delivery-card">
            <div class="card-body">
              <img
                className="landing-home-img"
                src="./images/header-mobile-img.png"
                alt=""
              />

            </div>
          </div>
        </div>
        <img className="landing-cir" src="./images/Ellipse2.png" alt="" />
      </div>
      <CookieConsent />
    </div>
  );
};

export default LandingHome;
