import "./food-section.css";

const foodsection = () => {
  return (
    <div className="welcome-section" id="food-section">
      <div className="welcome-heading">
        ForunPoint’s Customer-Centric Approach
      </div>
      <div className="welcome-text">
        At ForunPoint, we envision ourselves to be amongst the top online fresh food order & delivery services in Pakistan. We aim to provide an exquisite food catalogue, prompt deliveries, and top-notch services. Our Customer-Centric approach pushes us to strive for customer satisfaction and is one of our core values.
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-3">
            <div class="card">
              <div class="card-body">
                <img
                  className="food-section-img-1"
                  id="rest-image"
                  src="./images/restaurant01-1@2x.png"
                  alt=""
                />
              </div>
            </div>
            <div className="card-title">Restaurant</div>
          </div>
          <div class="col-md-6 col-lg-3">
            {/* <!-- Column 2 content --> */}
            <div class="card">
              <div class="card-body">
                <img
                  className="food-section-img"
                  id="flowers-card-img"
                  src="./images/profesional-chef01-1@2x.png"
                  alt=""
                />
              </div>
            </div>
            <div className="card-title">Flowers</div>
          </div>
          <div class="col-md-6 col-lg-3">
            {/* <!-- Column 3 content --> */}
            <div class="card">
              <div class="card-body">
                <img
                  className="food-section-img-4"
                  src="./images/shared-kitchen01-1@2x.png"
                  alt=""
                />
              </div>
            </div>
            <div className="card-title">Gifts</div>
          </div>
          <div class="col-md-6 col-lg-3">
            {/* <!-- Column 4 content --> */}
            <div class="card">
              <div class="card-body">
                <img
                  className="food-section-img-4"
                  id="riders-image"
                  src="./images/food-safety01-1@2x.png"
                  alt=""
                />
              </div>
            </div>
            <div className="card-title">Riders</div>
          </div>
        </div>
      </div>
      <img className="welcome-circle2" src="./images/ellipse3.png" alt="" />
    </div>
  );
};

export default foodsection;
