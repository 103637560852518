// import React, { useState, useEffect, useRef } from 'react';
// import './chatsupport.css';
// import io from 'socket.io-client';
// import { IoClose } from "react-icons/io5";

// const socket = io('http://localhost:3000');

// const ChatButton = () => {
//     const [isOpen, setIsOpen] = useState(false);
//     const [message, setMessage] = useState('');
//     const [messages, setMessages] = useState([]);
//     const chatRef = useRef(null);

//     useEffect(() => {
//         socket.on('chat message', (message) => {
//             setMessages(prevMessages => [...prevMessages, message,]); // Prepending new message
//         });
//     }, []);
//     const handleToggle = () => {
//         setIsOpen(!isOpen);
//     };

//     const handleMessageChange = (e) => {
//         setMessage(e.target.value);
//     };

//     const sendMessage = () => {
//         if (message.trim() !== '') {
//             const newMessage = {
//                 sender: 'Me',
//                 text: message,
//                 timestamp: new Date().toISOString()
//             };

//             setMessages(prevMessages => [...prevMessages, newMessage]);
//             socket.emit('chat message', newMessage);
//             setMessage('');
//         }
//     };

//     const handleClose = () => {
//         setIsOpen(false);
//     };



//     const handleKeyDown = (e) => {
//         if (e.key === 'Enter') {
//             e.preventDefault();
//             sendMessage();
//         }
//     };

//     return (
//         <div className="chat-button-container">
//             {isOpen && (
//                 <div className="chat-section" ref={chatRef}>
//                     <div className="chat-header">
//                         <p className='header-txt'>Chat With Rider <span className='close-ico' onClick={handleClose}><IoClose /></span></p>
//                     </div>
//                     <div className="msg-section">

//                         <div className="messages-container">
//                             {messages.map((msg, index) => (
//                                 <div key={index} className={`message-container ${msg.sender === 'Me' ? 'sent' : 'received'}`}>
//                                     <div className={`message-bubble ${msg.sender === 'Me' ? 'sent-bubble' : 'received-bubble'}`}>
//                                         {/* <div className="message-sender">{msg.sender}</div> */}
//                                         <div className="message-text">{msg.text}</div>
//                                         {/* <div className="message-timestamp">{new Date(msg.timestamp).toLocaleTimeString()}</div> */}
//                                     </div>
//                                 </div>
//                             ))}
//                         </div>

//                     </div>
//                     <input
//                         type="text"
//                         placeholder="Type your message..."
//                         value={message}
//                         onChange={handleMessageChange}
//                         onKeyDown={handleKeyDown}
//                     />
//                     <img src="./images/send-btn.png" onClick={sendMessage} className='send-btn' alt="" />
//                 </div>
//             )}
//             <img src='./images/chat-ico.png' className="chat-button" alt="Chat" onClick={handleToggle} />
//         </div>
//     );
// };

// export default ChatButton;



import React, { useState, useEffect, useRef } from 'react';
import './chatsupport.css';
import io from 'socket.io-client';
import { IoClose } from "react-icons/io5";
import chatIcon from '../../assets/images/chat-ico.png'

const socket = io('https://forunpoint-messenger-32c70a9f9d0c.herokuapp.com/');

const ChatButton = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const chatRef = useRef(null);

    useEffect(() => {
        socket.on('chat message', (message) => {
            setMessages(prevMessages => [...prevMessages, message]);
        });
    }, []);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const sendMessage = () => {
        if (message.trim() !== '') {
            const newMessage = {
                sender: 'Me',
                text: message,
                timestamp: new Date().toISOString()
            };

            setMessages(prevMessages => [...prevMessages, newMessage]);
            socket.emit('chat message', newMessage);
            setMessage('');
        }
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            sendMessage();
        }
    };

    return (
        <div className="chat-button-container">
            {isOpen && (
                <div className="chat-section" ref={chatRef}>
                    <div className="chat-header">
                        <p className='header-txt'>Chat With Rider <span className='close-ico' onClick={handleClose}><IoClose /></span></p>
                    </div>
                    <div className="msg-section">

                        <div className="messages-container">
                            {messages.map((msg, index) => (
                                <div key={index} className={`message-container ${msg.sender === 'Me' ? 'sent' : 'received'}`}>
                                    <div className={`message-bubble ${msg.sender === 'Me' ? 'sent-bubble' : 'received-bubble'}`}>
                                        {/* <div className="message-sender">{msg.sender}</div> */}
                                        <div className="message-text">{msg.text}</div>
                                        {/* <div className="message-timestamp">{new Date(msg.timestamp).toLocaleTimeString()}</div> */}
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                    <textarea
                        type="text"
                        placeholder="Type your message..."
                        value={message}
                        onChange={handleMessageChange}
                        onKeyDown={handleKeyDown}
                    />
                    <img src="./images/send-btn.png" onClick={sendMessage} className='send-btn' alt="" />
                </div>
            )}
            <img src={chatIcon} className="chat-button" alt="Chat" onClick={handleToggle} />
        </div>
    );
};

export default ChatButton;
