import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import SignupComponent from '../signup/signup';
import './login.css';
import { login } from '../../../shared/apis';
import { useNavigate } from 'react-router-dom';
import loginicon from '../../../assets/images/login-icon.png';
import personicon from '../../../assets/images/person-icon.png';
import lockicon from '../../../assets/images/lock-icon.png';
import googleicon from '../../../assets/images/google-icon.png';

const LoginComponent = ({ open, onCloseModal }) => {
  const [loginActive, setLoginActive] = useState(true);
  const [open2, setOpen2] = useState(false);
  const [signupMode, setSignupMode] = useState('email');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [user, setUser] = useState(null);
  const [fpUser, setFpUser] = useState(null);
  const navigate = useNavigate();

  const handleSignupClick = () => {
    setLoginActive(false);
    setOpen2(true);
    setSignupMode('email');
    onCloseModal();
  };

  const onCloseModal2 = () => {
    setOpen2(false);
    setLoginActive(false);
    setSignupMode('email');
  };

  // const signIn = async () => {
  //   if (!email || !password) {
  //     setError('Please provide both email and password.');
  //     return;
  //   }

  //   try {
  //     let response = await login({ email: email, password: password });
  //     let user = response.data.user;
  //     let fpUser = response.data.FpUser;
  //     let token = response.data.token;
  //     let fpToken = response.data.FpUser.token;

  //     setUser(user);
  //     setFpUser(fpUser);
  //     localStorage.setItem("token", JSON.stringify(token));
  //     localStorage.setItem("FpToken", JSON.stringify(fpToken));
  //     navigate('/restaurants');
  //   } catch (error) {
  //     if (error.response && error.response.status === 400) {
  //       setError('Invalid email or password!');
  //     } else {
  //       setError('An error occurred. Please try again.');
  //     }
  //   }
  // };

  const signIn = async () => {
    if (!email || !password) {
      setError('Please provide both email and password.');
      return;
    }

    try {
      let response = await login({ email: email, password: password });
      let user = response.data.user;
      let fpUser = response.data.FpUser;
      let token = response.data.token;
      let fpToken = response.data.FpUser.token;

      setUser(user);
      setFpUser(fpUser);
      localStorage.setItem("token", JSON.stringify(token));
      localStorage.setItem("FpToken", JSON.stringify(fpToken));
      navigate('/restaurants');

      onCloseModal(); // Close the modal
      window.location.reload(); // Refresh the current page
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError('Invalid email or password!');
        console.log(error.response )
        console.log(error.response.status )

      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };


  return (
    <>
      <div className="login-modal">
        <Modal open={open} onClose={onCloseModal} center styles={{
          modal: {
            width: '80%',
            maxWidth: '400px',
            height: 'auto',
          }
        }}>
          <div className="login-popup">
            <img src={loginicon} className='login-icon' alt="" />
            <br />
            <label htmlFor="Email" className='field-labels'>Email</label>
            <img className='login-icons1' src={personicon} alt="" />
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className='login-fields' required />
            <br />
            <label htmlFor="Password" className='field-labels' id='label-2'>Password</label>
            <img className='login-icons2' src={lockicon} alt="" />
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className='login-fields' required />
            <div className="checkbox">
              {/* <input type="checkbox" name="" id="" className='check-field' />&ensp;<span className='remember-text'>Remember Me</span> */}
            </div>
            {/* <img src={googleicon} className='social-icons' alt="" /> */}
            <button onMouseDown={signIn} className='login-btn-modal dialog-btn' onClose={onCloseModal}>Sign In</button>
            <br />
            <button className='signup-btn-modal dialog-btn' onClose={onCloseModal} onMouseDown={handleSignupClick}>Don't have an account?</button>
            {error && <p className="error-message">{error}</p>}
          </div>
        </Modal>
      </div>
      <div className="modal">
        <SignupComponent open={open2} onCloseModal={onCloseModal2} signupMode={signupMode} setSignupMode={setSignupMode} />
      </div>
    </>
  );
};

export default LoginComponent;