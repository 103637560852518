import React from 'react';
import './updates.css';

function Updates() {
  return (
    <div className='container'>
      <div className='updates-section'>
        <h3 className="text-center updates-heading my-5">Our Updates</h3>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="update-card">
              <a href='/blog'>
                <div className="card-body">
                  <img
                    className="updates-section-img"
                    src="./images/Gift.jpg"
                    alt=""
                  />
                </div>
              </a>
              <div className="update-card-title"><a className='update-link' href='/blog'>ForunPoint Spreading Joy, One Gift at a Time</a></div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="update-card">
              <a href='/blog'>
                <div className="card-body">
                  <img
                    className="updates-section-img"
                    src="./images/Voice.jpg"
                    alt=""
                  />
                </div>
              </a>
              <div className="update-card-title"><a className='update-link' href='/blog'>ForunPoint’s Solution with Voice Command Food Ordering</a></div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="update-card">
              <a href='/blog'>
                <div className="card-body">
                  <img
                    className="updates-section-img"
                    src="./images/third.jpeg"
                    alt=""
                  />
                </div>
              </a>
              <div className="update-card-title"><a className='update-link' href='/blog'>ForunPoint's Blueprint for Success in 2024</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Updates;
