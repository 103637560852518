import React, { useState, useEffect } from 'react';
import './cart.css';
import cartimg from "../../../assets/images/cart.png"
import { useNavigate } from 'react-router-dom';
import { createOrder, getMe, createCheckout, createTrip } from '../../../shared/apis';
import Toast from 'react-bootstrap/Toast';


const CartComponent = ({ showToast, pmId, itemsInCart, addToCart, paymentBy }) => {
    const [subTotal, setSubTotal] = useState(0);
    const [coordinates] = useState([31.3728262, 74.1592163]);
    const [restaurant, setRestaurant] = useState({});
    const [baseDeliveryFee] = useState(65);
    const [deliveryFee, setDeliveryFee] = useState(2);
    const [platformFee, setPlatformFee] = useState(0);
    const [cartItems, setCartItems] = useState(itemsInCart);
    const [tax, setTax] = useState(0);
    const [selectedTip, setSelectedTip] = useState(null);
    const [user, setUser] = useState(null);
    const [tipAmount, setTipAmount] = useState('');
    const [showOtherTipInput, setShowOtherTipInput] = useState(false);
    const [tipSectionPosition, setTipSectionPosition] = useState(0);
    const [loginActive, setLoginActive] = useState(true);
    const [orderType, setOrderType] = useState("PICKUP");
    const [isVisible, setIsVisible] = useState(true);
    const [showDeliveryFee, setshowDeliveryFee] = useState(false);
    const navigate = useNavigate();
    const [deliveryButtonStyle, setDeliveryButtonStyle] = useState({ backgroundColor: '#F3F3F3', color: '#d4110f' });
    const [pickupButtonStyle, setPickupButtonStyle] = useState({ backgroundColor: '#d4110f', color: '#F3F3F3' });
    let deliverycharges = 20;

    const init = async () => {
        let user = await getMe();
        setUser(user.data.user);
        // console.log(user.data.user.address, user.data.user.location.coordinates, "user for address")
        // console.log(itemsInCart)

    }

    useEffect(() => {
        init();
        const storedObjectString = localStorage.getItem('cartItems');
        if (storedObjectString) {
            const retrievedObject = JSON.parse(storedObjectString)
            setCartItems(retrievedObject);
            if (retrievedObject[0]?.orderType === 'PICKUP') {
                setshowDeliveryFee(false)
                setOrderType('PICKUP')
            } else {
                setshowDeliveryFee(true)
                setOrderType('DELIVERY')
            }
        }
        const currentURL = window.location.href;

        // Check if the URL contains a specific string
        if (currentURL.includes("order-details")) {
            // If the URL contains the specific string, hide the div
            setIsVisible(false);
        }
    }, [setCartItems]);

    setTimeout(() => {
        setCartItems(itemsInCart);
    }, 10);

    const haversine = (lat1, lon1, lat2, lon2) => {
        lat1 = toRadians(lat1);
        lon1 = toRadians(lon1);
        lat2 = toRadians(lat2);
        lon2 = toRadians(lon2);

        let dlat = lat2 - lat1;
        let dlon = lon2 - lon1;

        let a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2;
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const R = 6371;
        let distance = R * c;
        return distance.toFixed(2);
    }

    const toRadians = (degrees) => {
        return degrees * (Math.PI / 180);
    }



    const calculateTotal = () => {
        // let dFee = 10 * deliverycharges;
        setDeliveryFee(deliverycharges);
        let dishPrice = cartItems?.reduce((total, item) => total + item.quantity * item.cartItems.dish.price, 0);
        // setPlatformFee(Number(((dishPrice / 100) * 10).toFixed(2)));
        setPlatformFee(9);
        // setTax(Number((((dishPrice) / 100) * 8.25).toFixed(2))); For US
        setTax(Number((((dishPrice) / 100) * 16).toFixed(2)));
        // setSubTotal(dishPrice + tax + deliveryFee + platformFee);
        let tip = parseFloat(tipAmount) || 0; // Convert tipAmount to float, default to 0 if not provided
        let subtotalWithTip = (dishPrice + tax + deliveryFee + platformFee + tip);
        setSubTotal(subtotalWithTip);

    };
    // const calculateTotal = () => {
    //     let dishPrice = cartItems?.reduce((total, item) => {
    //             if (item.price > 0) {
    //                 return total + item.quantity * item.price;
    //             } else if (item.cartItems && item.cartItems.size && item.cartItems.size.price) {
    //                 return total + item.quantity * item.cartItems.size.price;
    //             }
    //             return total;
    //         }, 0);

    //     setPlatformFee(Number(((dishPrice / 100) * 10).toFixed(2)));
    //     setTax(Number((((dishPrice + platformFee + deliveryFee) / 100) * 8.25).toFixed(2)));
    //     // setSubTotal(dishPrice + tax + deliveryFee + platformFee);
    //     let tip = parseFloat(tipAmount) || 0; // Convert tipAmount to float, default to 0 if not provided
    //     let subtotalWithTip = (dishPrice + tax + deliveryFee + platformFee + tip);
    //     setSubTotal(subtotalWithTip);
    // };


    const increaseQuantity = (itemId) => {
        setCartItems(cartItems.filter(item => {
            if (item.id === itemId) {
                ++item.quantity;
                item.price = item.quantity * item.cartItems.dish.price;
            }
        }));

    };

    const decreaseQuantity = (itemId) => {
        setCartItems(cartItems.filter(item => {
            if (item.id === itemId) {
                --item.quantity;
                item.price = item.quantity * item.cartItems.dish.price;
            }
            if (item.quantity == 0) {
                const index = cartItems.indexOf(item);
                if (index > -1) {
                    setCartItems(cartItems.splice(index, 1));
                    setTimeout(() => {
                        localStorage.removeItem('cartItems');
                    }, 700);
                }
            }
        }));
    }

    const handleCheckout = () => {
        // if (!cartItems || cartItems.length === 0) {
        //     alert("Cart is empty. Please add something before checkout.");
        //     return;
        // }
        setshowDeliveryFee("false")
        cartItems[0].orderType = orderType;
        localStorage.setItem('cartItems', JSON.stringify(cartItems));

        if (!user || user === null || user._id === null) {
            showToast();
        } else {
            navigate('/order-details');
        }
    };

    const stringToObjectId = str => {
        const isValidHex = /^[0-9a-fA-F]{24}$/.test(str);
        if (isValidHex) {
            const objectId = {
                _bsontype: 'ObjectID',
                id: str,
                toString: function () {
                    return str;
                }
            };
            return objectId;
        } else {
            throw new Error('Invalid ObjectId');
        }
    };

    const placeOrder = async () => {
        let orderItems = [];
        itemsInCart.forEach(item => {
            let addons = [];
            item.cartItems.selectedAddons.forEach(addon => {
                addons.push({ addon: addon._id, quantity: 1 })
            })
            let items = {
                dish: item.id,
                quantity: item.quantity,
                addons: addons
            }
            orderItems.push(items);
        })
        let orderBody = {
            orderItems: orderItems,
            orderType: orderType,
            orderStatus: 'PENDING',
            stickerId: '',
            paymentBy: paymentBy,
        }

        let tripBody = {
            serviceId: stringToObjectId('61cc7a627a9c3679847ea78a'),
            pm: "Cod",
            fareAmount: 200,
            stripeToken: "2423432k4lj234lk3j24lk3j2kl43j2",
            stripeCustomerId: user.stripeCustomerId,
            paymentMethod: "STRIPE",
            paymentToken: "423432423423km",
            origin: [cartItems[0].cartItems.dish.user.location.coordinates[1], cartItems[0].cartItems.dish.user.location.coordinates[0]],
            sourceAddress: cartItems[0].cartItems.dish.user.address.street,
            destinationAddress: user.address.street,
            destination: [user.location.coordinates[1], user.location.coordinates[0]],
            specialNotes: "Good",
            vehicleType: 'FP_REGULAR_BIKE',
            isPetAllowed: false,
            isRequiredFemaleDriver: false,
            isFPTrip: false,
            paymentBy: paymentBy
        };
        console.log(tripBody)

        let trip = await createTrip(tripBody);
        console.log(trip)
        let order = await createOrder(orderBody);
        if (order.status.code === 200) {
            let checkout = {
                orderId: order.data.order._id,
                email: user.email,
                paymentMethod: pmId
            }
            let payment = await createCheckout(checkout);
            if (payment.status.code === 200) {
                console.log('Success', payment);
                localStorage.removeItem('cartItems');
                navigate('/restaurants');
            }
        }
    }

    const tipChange1 = () => {
        document.getElementById('tip-1').style.backgroundColor = '#d4110f';
        document.getElementById('tip-1').style.color = '#fff';
        document.getElementById('tip-2').style.backgroundColor = 'transparent';
        document.getElementById('tip-2').style.color = 'black';
        document.getElementById('tip-3').style.backgroundColor = 'transparent';
        document.getElementById('tip-3').style.color = 'black';
        document.getElementById('tip-4').style.backgroundColor = 'transparent';
        document.getElementById('tip-4').style.color = 'black';
        document.getElementById('tip-5').style.backgroundColor = 'transparent';
        document.getElementById('tip-5').style.color = 'black';
        setTipAmount('10');
    }

    const tipChange2 = () => {
        document.getElementById('tip-1').style.backgroundColor = 'transparent';
        document.getElementById('tip-1').style.color = 'black';
        document.getElementById('tip-2').style.backgroundColor = '#d4110f';
        document.getElementById('tip-2').style.color = '#fff';
        document.getElementById('tip-3').style.backgroundColor = 'transparent';
        document.getElementById('tip-3').style.color = 'black';
        document.getElementById('tip-4').style.backgroundColor = 'transparent';
        document.getElementById('tip-4').style.color = 'black';
        document.getElementById('tip-5').style.backgroundColor = 'transparent';
        document.getElementById('tip-5').style.color = 'black';
        setTipAmount('30');
    }

    const tipChange3 = () => {
        document.getElementById('tip-1').style.backgroundColor = 'transparent';
        document.getElementById('tip-1').style.color = 'black';
        document.getElementById('tip-2').style.backgroundColor = 'transparent';
        document.getElementById('tip-2').style.color = 'black';
        document.getElementById('tip-3').style.backgroundColor = '#d4110f';
        document.getElementById('tip-3').style.color = '#fff';
        document.getElementById('tip-4').style.backgroundColor = 'transparent';
        document.getElementById('tip-4').style.color = 'black';
        document.getElementById('tip-5').style.backgroundColor = 'transparent';
        document.getElementById('tip-5').style.color = 'black';
        setTipAmount('50');
    }

    const tipChange4 = () => {
        document.getElementById('tip-1').style.backgroundColor = 'transparent';
        document.getElementById('tip-1').style.color = 'black';
        document.getElementById('tip-2').style.backgroundColor = 'transparent';
        document.getElementById('tip-2').style.color = 'black';
        document.getElementById('tip-3').style.backgroundColor = 'transparent';
        document.getElementById('tip-3').style.color = 'black';
        document.getElementById('tip-4').style.backgroundColor = '#d4110f';
        document.getElementById('tip-4').style.color = '#fff';
        document.getElementById('tip-5').style.backgroundColor = 'transparent';
        document.getElementById('tip-5').style.color = 'black';
        setTipAmount('80');
    }

    const tipChange5 = () => {
        document.getElementById('tip-1').style.backgroundColor = 'transparent';
        document.getElementById('tip-1').style.color = 'black';
        document.getElementById('tip-2').style.backgroundColor = 'transparent';
        document.getElementById('tip-2').style.color = 'black';
        document.getElementById('tip-3').style.backgroundColor = 'transparent';
        document.getElementById('tip-3').style.color = 'black';
        document.getElementById('tip-4').style.backgroundColor = 'transparent';
        document.getElementById('tip-4').style.color = 'black';
        document.getElementById('tip-5').style.backgroundColor = '#d4110f';
        document.getElementById('tip-5').style.color = '#fff';
        handleOtherTipClick();
    }

    const handleTipChange = (e) => {
        setTipAmount(e.target.value);
    };

    const handleOtherTipClick = () => {
        setShowOtherTipInput(true);
    };

    const closeOtherTipClick = () => {
        setShowOtherTipInput(false);
    };
    // const selectOrderType = (type) => {
    //     setOrderType(type);
    //     if (type === "DELIVERY") {
    //         setDeliveryButtonStyle({ backgroundColor: '#d4110f', color: '#F3F3F3' });
    //         setPickupButtonStyle({ backgroundColor: '#F3F3F3', color: '#d4110f' });
    //     } else if (type === "PICKUP") {
    //         setDeliveryButtonStyle({ backgroundColor: '#F3F3F3', color: '#d4110f' });
    //         setPickupButtonStyle({ backgroundColor: '#d4110f', color: '#F3F3F3' });

    //     }
    // };
    const selectOrderType = (type) => {
        setOrderType(type);
        if (type === "DELIVERY") {
            setshowDeliveryFee(true); // Show delivery fee
            setDeliveryButtonStyle({ backgroundColor: '#d4110f', color: '#F3F3F3' });
            setPickupButtonStyle({ backgroundColor: '#F3F3F3', color: '#d4110f' });
        } else if (type === "PICKUP") {
            setshowDeliveryFee(false); // Hide delivery fee
            setDeliveryButtonStyle({ backgroundColor: '#F3F3F3', color: '#d4110f' });
            setPickupButtonStyle({ backgroundColor: '#d4110f', color: '#F3F3F3' });
        }
    };




    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        calculateTotal();
    }, [subTotal, platformFee, deliveryFee, tax, cartItems, itemsInCart, addToCart, pmId, decreaseQuantity, increaseQuantity]);

    return (
        <>
            <div className='cart'>
                <h2 className='cart-title'>Cart Items</h2>
                <div className="cart-items-container">
                    {cartItems && cartItems.length > 0 ? (
                        cartItems.map((item) => (
                            // <div key={item.id} className='cart-items'>
                            //     <div className="quantity-btns">
                            //         <button className='decrease-btn' onClick={() => decreaseQuantity(item.id)}>-</button>
                            //         <span>{item.quantity}</span>
                            //         <button className='increase-btn' onClick={() => increaseQuantity(item.id)}>+</button>
                            //     </div>
                            //     &ensp;
                            //     <img src={item.image} className='item-pic' />
                            //     <span className='item-name'>{item.name.substring(0, 15)}</span>
                            //     <span className='item-price'>
                            //         {item.price != null && `$${Number(item.price).toFixed(2)}`}
                            //     </span>

                            //         <span className='item-price'>
                            //             `$${Number(item?.cartItems?.size?.price).toFixed(2)}`
                            //         </span>

                            // </div>

                            <div className='cart-items'>
                                <div className="quantity-btns">
                                    <button className='decrease-btn' onClick={() => decreaseQuantity(item.id)}>-</button>
                                    <span>{item.quantity}</span>
                                    <button className='increase-btn' onClick={() => increaseQuantity(item.id)}>+</button>
                                </div>
                                &ensp;
                                <img src={item.image} className='item-pic' />
                                <span className='item-name'>{item.name.substring(0, 15)}</span>
                                {(item.price != null && (
                                    <span className='item-price'>
                                        Rs.{Number(item.price).toFixed(2)}
                                    </span>
                                )) || (item?.cartItems?.size?.price != null && (
                                    <span className='item-price'>
                                        Rs.{Number(item.cartItems.size.price).toFixed(2)}
                                    </span>
                                ))}
                            </div>

                        ))
                    ) : (
                        <div className="empty-cart">
                            <br />
                            <img src={cartimg} className="cart-icon" />
                            <p className="cart-desc">Add anything to your cart!</p>
                        </div>
                    )}
                </div>
                {cartItems && cartItems.length > 0 && (
                    <div>
                        {isVisible && (<div className="order-options">
                            {/* <button
                                id='login-btns'
                                className={`btn pickup-btn btn-primary nav-item login-btn ${loginActive ? 'login-active' : ''}`}
                                onClick={() => selectOrderType("DELIVERY")}
                                onMouseDown={handleLoginClick}
                            >
                                Delivery
                            </button>
                            <button
                                className={`btn delivery-btn btn-primary nav-item signup-btn ${!loginActive ? 'signup-active' : ''}`}
                                onClick={() => selectOrderType("PICKUP")}
                                id='signup-btns'
                                onMouseDown={handleSignupClick}
                            >
                                Pick Up
                            </button> */}
                            <button
                                className=" pickup-btn  "
                                style={pickupButtonStyle}
                                onClick={() => selectOrderType("PICKUP")}
                            >
                                Pick Up
                            </button>
                            <button
                                className=" delivery-btn  "
                                style={deliveryButtonStyle}
                                onClick={() => selectOrderType("DELIVERY")}
                            >
                                Delivery
                            </button>
                        </div>
                        )}
                        <div className="prices-content">
                            <div className='tip-section'>
                                <h3 className='tip-heading'>Give Tip</h3>
                                <div className="tip-contain">
                                    <button className='tip-button' id='tip-1' onClick={() => tipChange1()}>Rs.10</button>
                                    <button className='tip-button' id='tip-2' onClick={() => tipChange2()}>Rs.30</button>
                                    <button className='tip-button' id='tip-3' onClick={() => tipChange3()}>Rs.50</button>
                                    <button className='tip-button' id='tip-4' onClick={() => tipChange4()}>Rs.80</button>
                                    <button className='tip-button' id='tip-5' onClick={() => tipChange5()}>Other</button>
                                </div>
                            </div>
                            {showOtherTipInput && (
                                <div>
                                    <input
                                        type="number"
                                        className="other-tip-input"
                                        placeholder="Enter tip amount"
                                        value={tipAmount}
                                        onChange={handleTipChange}
                                    />
                                </div>
                            )}
                            <div className="price-sec">
                                <div className='subtotal-section'>
                                    <h3 className='subtotal-title'>Subtotal</h3>
                                    <p className='subtotal-amount'>
                                        Rs. {subTotal.toFixed(0)}
                                    </p>
                                </div>
                                {/* {orderType &&

                                    (<div className='delivery-section'>
                                        <h3 className='delivery-title'>Delivery Fee</h3>
                                        <p className='delivery-amount'>
                                            Rs. {(deliverycharges = haversine(coordinates[0], coordinates[1], cartItems[0].cartItems.dish.user.location.coordinates[1], cartItems[0].cartItems.dish.user.location.coordinates[0]) * 10 + baseDeliveryFee).toFixed(0)}
                                        </p>
                                    </div>
                                    )} */}
                                {/* {showDeliveryFee ? (
                                    <div className='delivery-section'>
                                        <h3 className='delivery-title'>Delivery Fee</h3>
                                        <p className='delivery-amount'>
                                            Rs. {(deliverycharges = haversine(coordinates[0], coordinates[1], cartItems[0].cartItems.dish.user.location.coordinates[1], cartItems[0].cartItems.dish.user.location.coordinates[0]) * 10 + baseDeliveryFee).toFixed(0)}
                                        </p>
                                    </div>
                                ) : (
                                    <div className='delivery-section'>
                                        <h3 className='delivery-title'>Delivery Fee</h3>
                                        <p className='delivery-amount'>
                                            Rs. {deliverycharges = 0}
                                        </p>
                                    </div>
                                )} */}

                                <div className='delivery-section'>
                                    <h3 className='delivery-title'>Delivery Fee</h3>
                                    <p className='delivery-amount'>
                                        Rs. {!showDeliveryFee ?
                                            deliverycharges = 0 :
                                            (deliverycharges = haversine(
                                                coordinates[0],
                                                coordinates[1],
                                                cartItems[0].cartItems.dish.user.location.coordinates[1],
                                                cartItems[0].cartItems.dish.user.location.coordinates[0]
                                            ) * 10 + baseDeliveryFee).toFixed(0)
                                        }
                                    </p>
                                </div>



                                < div className='platform-section'>
                                    <h3 className='platform-title'>Platform Fee</h3>
                                    <p className='platform-amount'>
                                        Rs. {platformFee.toFixed(0)}
                                    </p>
                                </div>
                                <div className='tax-section'>
                                    <h3 className='tax-title'>Tax</h3>
                                    <p className='tax-amount'>
                                        Rs. {tax.toFixed(0)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {!pmId && <button className={`checkout-btn ${(!itemsInCart || itemsInCart.length === 0) ? 'disabled-checkout-btn' : ''}`}
                    onClick={handleCheckout}
                    disabled={!itemsInCart || itemsInCart.length === 0}
                >
                    Checkout
                </button>}
                {paymentBy && <button className={`checkout-btn ${itemsInCart && itemsInCart.length > 0 ? '' : 'disabled-checkout-btn'}`} onClick={placeOrder}>
                    Place Order
                </button>}
            </div >
        </>
    );
};

export default CartComponent;