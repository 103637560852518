import React, { useState, useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import './Navbar.css';
import { BsBoxSeam } from "react-icons/bs";
import { BsJournalBookmark } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { FaRegHeart } from "react-icons/fa";
import { TbLogout2 } from "react-icons/tb";
import { TbMessageCircleQuestion } from "react-icons/tb";
import LoginComponent from '../../componentes/dialogs/login/login';
import SignupComponent from '../../componentes/dialogs/signup/signup';
import logo from '../../assets/images/logo01-1@2x.png';
import cartitem from '../../assets/images/user-cart-logo.png'
import profileIco from '../../assets/images/pfp.png'
import { IoMdArrowDropdown } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { getMe } from '../../shared/apis';
import CartComponent from '../home/cart/cart';
// import { AiOutlineClose } from "react-icons/ai";

function Navbar({ itemsInCart }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [showDropdownReg, setShowDropdownReg] = useState(false);
  const [showDropdownReg2, setShowDropdownReg2] = useState(false);
  const [loginActive, setLoginActive] = useState(true);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [signupMode, setSignupMode] = useState('email');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [showCartPanel, setShowCartPanel] = useState(false);
  const [showCartPopup, setShowCartPopup] = useState(false);
  const [cartItems, setCartItems] = useState(itemsInCart);

  const toggleCartPopup = () => {
    setShowCartPopup(!showCartPopup);
  };

  const init = async () => {
    let user = await getMe();
    setUser(user.data.user);
  };

  useEffect(() => {
    init();
    const userToken = localStorage.getItem('token');
    if (userToken) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);


  useEffect(() => {
    const storedObjectString = localStorage.getItem('cartItems');
    const retrievedObject = JSON.parse(storedObjectString)
    if (retrievedObject) {
      setCartItems(retrievedObject);
    }
  }, [showCartPopup]);

  // const handleCartItemClick = () => {
  //   setShowCartPanel(!showCartPanel);
  // };

  // const addToCart = (item) => {
  //   const existingItem = cartItems.find((cartItem) => cartItem.id === item.id);

  //   if (existingItem) {
  //     setCartItems((prevCartItems) =>
  //       prevCartItems.map((cartItem) =>
  //         cartItem.id === item.id
  //           ? { ...cartItem, quantity: cartItem.quantity + 1 }
  //           : cartItem
  //       )
  //     );
  //   } else {
  //     setCartItems((prevCartItems) => [...prevCartItems, item]);
  //   }
  //   setTimeout(()=> {
  //     console.log(cartItems)
  //   },600);
  // };


  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleDropdownToggleReg = () => {
    setShowDropdownReg(!showDropdownReg);
  };

  const handleOptionClick = (option) => {
    setShowDropdownReg(false);
  };

  const handleDropdownToggle2 = () => {
    setShowDropdown2(!showDropdown2);
  };

  const handleDropdownToggleReg2 = () => {
    setShowDropdownReg2(!showDropdownReg2);
  };

  const handleOptionClick2 = (option) => {
    setShowDropdownReg2(false);
  };

  const handleLoginClick = () => {
    setLoginActive(true);
    document.getElementById('login-btn').style.backgroundColor = '#d4110f';
    document.getElementById('login-btn').style.color = '#F3F3F3';
    document.getElementById('signup-btn').style.backgroundColor = '#F3F3F3';
    document.getElementById('signup-btn').style.color = '#d4110f';
    setOpen(true);
  };

  const handleSignupClick = () => {
    setLoginActive(false);
    document.getElementById('login-btn').style.backgroundColor = '#F3F3F3';
    document.getElementById('login-btn').style.color = '#d4110f';
    document.getElementById('signup-btn').style.backgroundColor = '#d4110f';
    document.getElementById('signup-btn').style.color = '#F3F3F3';
    setOpen2(true);
    setSignupMode('email');
  };

  const onCloseModal = () => {
    setOpen(false);
    setLoginActive(true);
  };

  const onCloseModal2 = () => {
    setOpen2(false);
    setLoginActive(false);
    setSignupMode('email');
  };

  const logoutUser = () => {
    localStorage.clear();
    navigate('/');
  }

  const handleProfileIconClick = () => {
    handleDropdownToggle();
    if (!isLoggedIn) {
      setOpen(true); // Open the login component
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img className="logo-navbar" src={logo} alt="ForunPoint-Logo" />
        </a>
        {isLoggedIn && <div className="nav-item dropdown prfile_dropdown">
          <div
            className={`nav-link dropdowntogglestyle text-center ${showDropdown2 ? 'selected' : ''}`}
            role="button"
            onClick={() => { handleDropdownToggleReg2() }}
          >
            <img src={user?.picture} className='user-profile-img' alt="" />
            <span className="user-name">{user?.firstName.substring(0, 8)}</span> <IoMdArrowDropdown />
          </div>
          <div className={`dropdown-menu ${showDropdownReg2 ? 'show' : ''}`}>
            <a className="dropdown-item" onClick={() => handleOptionClick2('restaurant')} href="/profile-page">
              <CgProfile className='orders-ico' /> Profile
            </a>
            <a className="dropdown-item" onClick={() => handleOptionClick2('driver')} href="/past-orders">
              <BsJournalBookmark className='orders-ico' /> Past Orders
            </a>
            <a className="dropdown-item" onClick={() => handleOptionClick2('driver')} href="/active-orders">
              <BsBoxSeam className='orders-ico' /> Active Orders
            </a>
            {/* <a className="dropdown-item" onClick={() => handleOptionClick2('driver')} href="/favourites">
              <FaRegHeart className='orders-ico' /> Favourites
            </a> */}
            {/* <a className="dropdown-item" onClick={() => handleOptionClick2('driver')} href="">
              <TbMessageCircleQuestion className='orders-ico' /> Help Center
            </a> */}
            <a className="dropdown-item" onClick={() => handleOptionClick2('driver')} href="">
              <TbLogout2 className='order-ico' /> <span className='logout-txt' onClick={logoutUser}>Logout</span>
            </a>
          </div>
        </div>

        }
        <div className='login-btns-pos'>
          {!isLoggedIn && <button
            className="navbar-toggler"
            type="button"
            onClick={handleDropdownToggle}
          >
            <img className='user_profile_icon' src={profileIco} alt="" onClick={handleProfileIconClick} />

          </button>
          }
          <img className='user_cart_icon' src={cartitem} alt="" onClick={toggleCartPopup} />
          <div className={`cart-popup ${showCartPopup ? 'open' : ''}`}>
            <button className="close-popup" onClick={toggleCartPopup}>
              <AiOutlineClose />
            </button>
            <CartComponent itemsInCart={cartItems} />
          </div>
        </div>

        {!isLoggedIn && <div className={`collapse navbar-collapse ${showDropdown ? 'show' : ''}`}>
          <div className="navbar-nav ms-auto">
            <div>
              <div className="btns">
                <button
                  id='login-btn'
                  className={`login-btn ${loginActive ? 'login-active' : ''}`}
                  onMouseDown={handleLoginClick}
                >
                  Sign In
                </button>
                <button
                  className={` signup-btn ${!loginActive ? 'signup-active' : ''}`}
                  id='signup-btn'
                  onMouseDown={handleSignupClick}
                >
                  Sign Up
                </button>
              </div>
            </div>
            <div className='modal'>
              <LoginComponent open={open} onCloseModal={onCloseModal} />
            </div>
            <div className='modal'>
              <SignupComponent open={open2} onCloseModal={onCloseModal2} signupMode={signupMode} setSignupMode={setSignupMode} />
            </div>
            {/* <div className="nav-item dropdown">
              <div
                className={`nav-link custom-contact-button text-center ${showDropdown ? 'selected' : ''}`}
                role="button"
                onClick={() => { handleDropdownToggleReg() }}
              >
                Join Us
              </div>
              <div className={`dropdown-menu ${showDropdownReg ? 'show' : ''}`}>
                <a className="dropdown-item" onClick={() => handleOptionClick('restaurant')} href="/join-us">
                  Join as Restaurant
                </a>
                <a className="dropdown-item" onClick={() => handleOptionClick('driver')} href="/driver">
                  Join as Driver
                </a>
              </div>
            </div> */}
          </div>
        </div>
        }
      </div>
    </nav>
  );
}

export default Navbar;