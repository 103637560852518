// import "./driver-app.css";

// const RestaurantSection = () => {
//   return (
//     <div className="resturant-div">
//     <div className="container resturant-section" id="about">
//       <div className="row">
//         <div className="col-md-6 col-lg-5">
//           <div className="about-card">
//             <div className="card-body">
//               <img
//                 className="rider-section-img"
//                 src="./images/driver-section.png"
//                 alt=""
//               />
//             </div>
//           </div>
//         </div>
//         <div className="col-md-6 col-lg-6">
//           <div className="about-card-content">
//             <div className="card-body resturant-contant">
//               <ul>

//                 <li className="resturant-headings">ForunPoint Rider:</li>
//                 <p className="resturant-section-content">
//                 Join the ForunPoint delivery team today and experience a new level of service! Our driver app prioritises safety, security, and seamless communication, constantly evolving to meet your needs. As a new driver, enjoy recognition for your hard work and the freedom to manage your own schedule. Join our vibrant network of delivery partners and unlock better incentives, bonus earnings, performance tracking, and badge rewards. Install ForunPoint Rider now and embark on your journey as a rider!
//                 </p>
//                 <div className="link-block">
//                   <p className="link-tile">Driver App</p>
//                   <a
//                     href="https://play.google.com/store/apps/details?id=com.whetcloud.forunpoint.driver"
//                     target="_blank"
//                     rel="noreferrer"
//                   >
//                     <img
//                       className="link-img"
//                       src="./images/playstore.png"
//                       alt=""
//                     />
//                   </a>
//                   <a
//                     href="https://apps.apple.com/us/app/forunpoint/id1630196197"
//                     target="_blank"
//                     rel="noreferrer"
//                   >
//                     <img
//                       className="link-img"
//                       src="./images/appstore.png"
//                       alt=""
//                     />
//                   </a>
//                 </div>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//     </div>
//   );
// };

// export default RestaurantSection;



import "./driver-app.css";

const RestaurantSection = () => {
  return (
    <div className="resturant-div">
      <div className="container resturant-section" id="about">
        <div className="row">
          <div className="col-md-6 col-lg-5 order-md-1">
            <div className="about-card">
              <div className="card-body">
                <img
                  className="rider-section-img"
                  src="./images/driver-section.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 order-md-2">
            <div className="about-card-content">
              <div className="card-body resturant-contant">
                <ul>
                  <li className="resturant-headings">ForunPoint Rider:</li>
                  <p className="resturant-section-content">
                    Join the ForunPoint delivery team today and experience a new level of service! Our driver app prioritizes safety, security, and seamless communication, constantly evolving to meet your needs. As a new driver, enjoy recognition for your hard work and the freedom to manage your own schedule. Join our vibrant network of delivery partners and unlock better incentives, bonus earnings, performance tracking, and badge rewards. Install ForunPoint Rider now and embark on your journey as a rider!
                  </p>
                  <div className="link-block">
                    <p className="link-tile">Driver App</p>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.whetcloud.forunpoint.driver"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="link-img"
                        src="./images/playstore.png"
                        alt=""
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/us/app/forunpoint/id1630196197"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="link-img"
                        src="./images/appstore.png"
                        alt=""
                      />
                    </a>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantSection;
