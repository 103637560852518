// import "./about-section.css";

// const AboutSection = () => {
//   return (
//     <>
//       <div className="container about-section" id="about">
//         <div className="row">
//           <div className="col-md-6 col-lg-5">
//             <div className="about-card">
//               <div className="card-body">
//                 <img
//                   className="about-section-img"
//                   src="./images/about01.png"
//                   alt=""
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="col-md-6 col-lg-6">
//             {/* <!-- Column 2 content --> */}
//             <div className="about-card-content">
//               <div className="card-body">
//                 <h2 className="about-section-heading">About ForunPoint</h2>
//                 <img
//                   src="./images/about-underline.png"
//                   alt=""
//                   className="underline"
//                 />
//                 &nbsp;
//                 <img
//                   src="./images/about-underline-s.png"
//                   alt=""
//                   className="underline"
//                 />
//                 &nbsp;
//                 <img
//                   src="./images/about-underline-xs.png"
//                   alt=""
//                   className="underline"
//                 />
//                 <p className="about-section-content">
//                 Welcome to ForunPoint, your gateway to exceptional dining experiences. Our platform is dedicated to bringing mouth-watering dishes from top restaurants right to your doorstep. With a focus on accessibility and convenience, ForunPoint offers a diverse range of culinary delights tailored to individual tastes.
//                   <br />
//                   <br /> What sets ForunPoint apart is our unparalleled search capability, allowing users to explore hundreds of unique and popular dishes based on country or dish reference. Whether you're craving authentic Mexican cuisine, comforting Italian pasta, or exotic Thai flavours, ForunPoint delivers.
//                   <br /> <br /> Experience the convenience of ordering delicious meals from your favourite restaurants directly from your mobile device with ForunPoint's online food ordering platform. With real-time order tracking and a seamless user experience, ForunPoint is here to satisfy your cravings and elevate your dining experience. Join us today and discover a world of culinary delights at your fingertips.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AboutSection;


import "./about-section.css";

const AboutSection = () => {
  return (
    <>
      <div className="container about-section" id="about">
        <div className="row">
          <div className="col-md-6 col-lg-6 order-md-2">
            {/* <!-- Column 2 content --> */}
            <div className="about-card-content">
              <div className="card-body">
                <h2 className="about-section-heading">About ForunPoint</h2>
                <img
                  src="./images/about-underline.png"
                  alt=""
                  className="underline"
                />
                &nbsp;
                <img
                  src="./images/about-underline-s.png"
                  alt=""
                  className="underline"
                />
                &nbsp;
                <img
                  src="./images/about-underline-xs.png"
                  alt=""
                  className="underline"
                />
                <p className="about-section-content">
                  Welcome to ForunPoint, your gateway to exceptional dining experiences. Our platform is dedicated to bringing mouth-watering dishes from top restaurants right to your doorstep. With a focus on accessibility and convenience, ForunPoint offers a diverse range of culinary delights tailored to individual tastes.
                  <br />
                  <br /> What sets ForunPoint apart is our unparalleled search capability, allowing users to explore hundreds of unique and popular dishes based on country or dish reference. Whether you're craving authentic Mexican cuisine, comforting Italian pasta, or exotic Thai flavours, ForunPoint delivers.
                  <br /> <br /> Experience the convenience of ordering delicious meals from your favourite restaurants directly from your mobile device with ForunPoint's online food ordering platform. With real-time order tracking and a seamless user experience, ForunPoint is here to satisfy your cravings and elevate your dining experience. Join us today and discover a world of culinary delights at your fingertips.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-5 order-md-1">
            <div className="about-card">
              <div className="card-body">
                <img
                  className="about-section-img"
                  src="./images/about01.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSection;
