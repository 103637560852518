import "./footer.css";
import logo from '../../assets/images/logo01-1@2x.png';
function Footer() {
  return (
    <div className="section-footer">
      <section></section>
      <footer className="footer-bs">
        <div className="row">
          <div className="col-md-3 footer-brand animated fadeInLeft">
            <img
              className="logo-footer"
              src={logo}
              alt="Footer logo"
            ></img>
            <p className="mt-2">
              At ForunPoint, our vision is to be among the top online fresh food
              delivery service. The mission is to have a
              great food selection.
            </p>
          </div>
          <div className="col-md-2 footer-nav animated fadeInUp">
            <h4 className="footer-heading">Services</h4>
            <div className="col-md-6">
              <ul className="pages">
                <li>
                  <a href="#food-section">Restaurant</a>
                </li>
                <li>
                  <a href="#food-section">Gifts & Flowers</a>
                </li>
                <li>
                  <a href="/careers">Careers</a>
                </li>
                <li>
                  <a href="/terms">Terms & Conditions</a>
                </li>
                <li>
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/faqs">FAQ's</a>
                </li>
                <li>
                  <a href="/forunpoint-solution">ForunPoint's Solution</a>
                </li>
                <li>
                  <a href="/message-ceo">Message from CEO</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-2 footer-nav animated fadeInUp">
            <h4 className="footer-heading">Reach Us</h4>
            <div className="col-md-6">
              <ul className="pages">
                <li>
                  <a href="/join-us">Join as Restaurant</a>
                </li>
                <li>
                  <a href="/driver">Join as Rider</a>
                </li>
                <li>
                  <a href="/contact-us">Contact Us</a>
                </li>
                <li>
                  <a href="https://console.forunpoint.com/">Restaurant Dashboard</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-2 footer-social animated fadeInDown">
            <h4 className="footer-heading">Follow Us</h4>
            <ul>
              <li>
                <a href="https://www.facebook.com/forunpoint.pk/" target="_blank" >Facebook</a>
              </li>
              <li>
                <a href="https://twitter.com/Forunpointpk" target="_blank" >Twitter</a>
              </li>
              <li>
                <a href="https://www.instagram.com/forunpoint.pk/" target="_blank" >Instagram</a>
              </li>
              <li>
                <a href="https://www.youtube.com/@forunpointpakistan" target="_blank" >Youtube</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/forunpointpk" target="_blank" >Linkedin</a>
              </li>
              <li>
                <a href="https://www.tiktok.com/@forunpoint.pk" target="_blank" >Tiktok</a>
              </li>
              <li>
                <a href="https://www.pinterest.com/forunpointpk/" target="_blank" >Pinterest </a>
              </li>
              <li>
                <a href="mailto:info@forunpoint.com">Email Us</a>
              </li>
            </ul>
          </div>
          <div className="col-md-3 footer-ns animated fadeInRight">
            <h4 className="footer-heading">Join our Newsletter</h4>
            {/* <p>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                ></input>
                <span className="input-group-btn">
                  <button className="btn btn-default" type="button">
                    <svg
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-envelope"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                    </svg>
                  </button>
                </span>
              </div>
            </p> */}



            <p>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control border"
                  placeholder="Email"
                ></input>
                <span className="input-group-btn">
                  <button className="btn btn-default" type="button">
                    <svg
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-envelope"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                    </svg>
                  </button>
                </span>
              </div>
            </p>




            {/* <a
              className="dashboard-btn mt-5"
              href="https://console.forunpoint.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Restaurant Dashboard
            </a> */}
          </div>
        </div>
      </footer>
      <div className="footer-bottom">
        <div>© 2024 ForunPoint. All Rights Reserved.</div>
      </div>
    </div>
  );
}

export default Footer;
