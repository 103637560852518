import React, { useState } from 'react';
import Cookies from 'js-cookie';
import './cookies-consent.css';

function CookieConsent() {
    const [showConsent, setShowConsent] = useState(!Cookies.get('cookie_consent'));

    const handleAccept = () => {
        Cookies.set('cookie_consent', 'accepted');
        setShowConsent(false);
    };

    const handleReject = () => {
        setShowConsent(false);
    };

    return (
        <div>
            {showConsent && (
                <div className="cookie-consent">
                    <div>
                        <p className='coookies-main'>We Use Cookies</p>
                        <p className='cookies-desc'>Cookies help us display personalized product recommendations and ensure you have a great shopping experience. We never collect any personal data.</p>
                    </div>
                    <div className='cookie-btns'>
                        <button onClick={handleReject} className="reject-btn">Reject All</button>
                        <button onClick={handleAccept} className="accept-btn">Accept All</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CookieConsent;
