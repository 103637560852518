import FoodSection from "./food-section/food-section";
import AboutSection from "./about-section/about-section";
import DeliverySection from "./delivery-section/delivery-secion";
import DisheSection from "./dishes-section/dishes-section";
import CustomerApp from "./customer-app/customer-app";
import RestaurantApp from "./restaurant-app/restaurant-app";
import DriverApp from "./driver-app/driver-app";
import LandingHome from "./homepage/LandingHome";
import BlogSection from "./updates/updates";

const HomePage = () => {
  return (
    <div>
      <LandingHome />
      <FoodSection />
      <AboutSection />
      <DeliverySection />
      <DisheSection />
      <CustomerApp />
      <RestaurantApp />
      <DriverApp />
      <BlogSection />
    </div>
  );
};

export default HomePage;