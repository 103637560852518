import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import ProgressBar from 'react-bootstrap/ProgressBar';
import './active-orders.css';
import { getOrdersList } from '../../shared/apis';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import Spinner from 'react-bootstrap/Spinner';
import { FaStar } from 'react-icons/fa';
import 'react-responsive-modal/styles.css';
import ChatSupport from '../../componentes/chat-support/chatsupport';


function ActiveOrders() {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const navigate = useNavigate();
    const [modalHeight, setModalHeight] = useState('450px'); // Default height for screens above 768px width
    const [mealRating, setMealRating] = useState(0); // State for meal rating
    const [riderRating, setRiderRating] = useState(0); // State for rider rating
    const [openModalWidth, setOpenModalWidth] = useState(false); // State variable to control modal width

    const init = async () => {
        setIsLoading(true);
        try {
            let allOrders = await getOrdersList(0, 20, 'IN_PROGRESS');
            setOrders(allOrders.data.orders);
        } catch (error) {
            console.error('Error fetching orders:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setModalHeight('350px');
                setOpenModalWidth(true); // Set a state variable to indicate whether to set modal width to 100%
            } else {
                setModalHeight('450px');
                setOpenModalWidth(false); // Set the state variable to false if screen width is greater than 768px
            }
        };

        handleResize(); // Call the function once to set the initial state

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handleCardClick = (orderId) => {
        navigate(`/order-details-main/${orderId}`);
    };

    const handleOrderOpen = () => {
        setOpen(true);
        setMealRating(0);
    };

    const handleOrderClose = () => {
        setOpen(false);
        setMealRating(0); // Reset meal rating when modal is closed
    };

    const handleMealRatingChange = (newRating) => {
        setMealRating(newRating);
        setOpen(false);
        setOpen2(true);
    };

    const handleRiderRatingChange = (newRating) => {
        setRiderRating(newRating);
        setOpen(false);
        setOpen2(true);
    };

    const handleRatingClose = () => {
        setOpen2(false);
        setMealRating(0);
        setRiderRating(0);
    };

    return (
        <>
            <Navbar />
            <ChatSupport />

            <div className="active-orders">
                <div className="active-orders-wrapper">
                    <h2 className='active-orders-heading'>Active Orders</h2>
                    <p className='active-orders-desc'>Orders history you did with this account</p>
                </div>
                
                {isLoading &&
                    <div className="slider-content d-flex justify-content-center align-items-center">
                        <div className='mt-5' style={{ textAlign: 'center' }}>
                            <Spinner animation="border" variant="danger" />
                        </div>
                    </div>
                }

                {!isLoading && <div className="orders-content">
                    {orders.map((order) => (
                        <div className="active-orders-card" key={order._id} onClick={() => handleCardClick(order._id)}>
                            <div className="active-orders-content">
                                <div className='active-orders-left'>
                                    <img src={order?.orderItems[0]?.dish?.picture} className="active-orders-img" alt="" />
                                    <div className="orders-body-content">
                                        <p className='active-orders-title'>{order?.chef?.restaurantName}</p>
                                        {order.orderItems.map((item, index) => (
                                            <p key={index} className='active-orders-details'>{item.quantity}x {item?.dish?.dishName}</p>
                                        ))}
                                    </div>
                                </div>
                                <div className='active-orders-right'>
                                    <p className='active-orders-price'>Rs.{order.total}</p>
                                </div>
                            </div>
                            <div className='progress-section'>
                                <ProgressBar className='progress-bar-main'>
                                    <ProgressBar variant="success" now={25} key={1} className='progress-bar-1' />
                                    <ProgressBar variant="warning" now={25} key={2} className='progress-bar-2' />
                                    <ProgressBar variant="danger" now={25} key={3} className='progress-bar-3' />
                                    <ProgressBar variant="danger" now={25} key={4} className='progress-bar-4' />
                                </ProgressBar>
                            </div>
                        </div>
                    ))}
                </div>
                }
            </div>
            {/* <button onClick={handleOrderOpen}>Click Me!</button> */}
            <Modal open={open} onClose={handleOrderClose} classNames='Modal1' center styles={{
                modal: {
                    width: openModalWidth ? '100%' : '70%', // Set width to 100% if openModalWidth is true, otherwise use 70%
                    maxWidth: '380px',
                    height: modalHeight,
                }
            }}>
                <div className="order-complete">
                    <img src="/images/Group 328.png" alt="" className='order-complete-img' />
                    <h1>Order Complete</h1>
                    <p className='order-complete-text'>Your order has been completed successfully</p>
                    <div className="star-rating">
                        {[...Array(5)].map((star, index) => {
                            const ratingValue = index + 1;

                            return (
                                <FaStar
                                    key={index}
                                    className="star"
                                    color={(ratingValue <= mealRating) ? "#ffc107" : "#e4e5e9"}
                                    size={30}
                                    onClick={() => handleMealRatingChange(ratingValue)}
                                />
                            );
                        })}
                    </div>
                    <p className='rate-us-text'>Rate Us</p>
                </div>
            </Modal>
            <Modal open={open2} onClose={handleRatingClose} classNames='Modal1' center styles={{
                modal: {
                    width: openModalWidth ? '100%' : '70%', // Set width to 100% if openModalWidth is true, otherwise use 70%
                    maxWidth: '750px',
                    height: '470px',
                }
            }}>

                <div className="meal-rating">
                    <h1>Rate your Meal..!</h1>
                    <p className='rating-text'>Regardless of the meal's quality, let's have a discussion about it.</p>
                    <div className="star-rating">
                        {[...Array(5)].map((star, index) => {
                            const ratingValue = index + 1;

                            return (
                                <FaStar
                                    key={index}
                                    className="star"
                                    color={(ratingValue <= mealRating) ? "#ffc107" : "#e4e5e9"}
                                    size={30}
                                    onClick={() => handleMealRatingChange(ratingValue)}
                                />
                            );
                        })}
                    </div>
                    <p className='rating-experience'>Don't hesitate to describe your Pizza shop experience to others.</p>
                    <textarea className='meal-textbox' rows='4' cols='50' wrap='hard'></textarea>
                    <button className='meal-send-btn'>Send</button>
                </div>

                <div className="meal-rating">
                    <h1 className='rate-rider'>Rate your Rider..!</h1>
                    <p className='rating-text rider-text'>Regardless of the Time Management, let's have a discussion about it.</p>
                    <div className="rider-star-rating">
                        {[...Array(5)].map((star, index) => {
                            const ratingValue = index + 1;

                            return (
                                <FaStar
                                    key={index}
                                    className="star"
                                    color={(ratingValue <= riderRating) ? "#ffc107" : "#e4e5e9"}
                                    size={30}
                                    onClick={() => handleRiderRatingChange(ratingValue)}
                                />
                            );
                        })}
                    </div>
                    <textarea className='meal-textbox' rows='4' cols='50' wrap='hard'></textarea>
                    <button className='meal-send-btn'>Send</button>
                </div>

            </Modal>
        </>
    )
}

export default ActiveOrders;
